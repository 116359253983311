<template>
  <div></div>
</template>

<script>
  export default {
    name: 'SnackBar',

    props: {
      success: {
        required: false
      },
      notification: {
        required: false
      },
      warning: {
        required: false
      },
      error: {
        required: false
      },
      announcement: {
        required: false
      },
      version: {
        required: false
      },
      successMsg: {
        required: false
      },
      notificationMsg: {
        required: false
      },
      warningMsg: {
        required: false
      },
      errorMsg: {
        required: false
      },
      announcementMsg: {
        required: false
      },
      versionMsg: {
        required: false
      },
    },

    watch: {
      success() {
        if (this.success) {
            this.$snotify.success(this.successMsg, 'HINWEIS', {
            timeout: 10000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightBottom',
            html: '<span style="color: white"><b style="font-size: large">HINWEIS</b><br>' + this.successMsg + '</span>',
            style: 'material',
          });

          this.$store.commit('successMsg', '');
        }
      },
      notification() {
        if (this.notification) {
          this.$snotify.info(this.successMsg, 'BENACHRICHTIGUNG', {
            timeout: 60000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightBottom',
            html: '<span style="color: white"><b style="font-size: large">BENACHRICHTIGUNG</b><br>' + this.notificationMsg + '</span>',
            style: 'material',
          });
          this.$store.commit('notificationMsg', '');
        }
      },
      warning() {
        if (this.warning) {
         this.$snotify.warning(this.warning, 'ACHTUNG', {
            timeout: 30000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightBottom',
            html: '<span style="color: white"><b style="font-size: large">ACHTUNG</b><br>' + this.warningMsg + '</span>',
            style: 'material',
          });

          this.$store.commit('warningMsg', '');
        }
      },
      error() {
        if (this.error) {
         this.$snotify.error(this.errorMsg, 'FEHLER', {
            timeout: 15000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightBottom',
            html: '<span style="color: white"><b style="font-size: large">FEHLER</b><br>' + this.errorMsg + '</span>',
            style: 'material',
          });

          this.$store.commit('errorMsg', '');
        }
      },
      announcement() {
        if (this.announcement) {
          this.$store.commit('announcementMsg', '');

          let showAgain = sessionStorage.getItem('sose.announcementMsg.showAgain');
          if (showAgain && showAgain === 'false') {
            return;
          }

          if (!this.windowActive) {
            return;
          }

          this.$snotify.html('<span style="color: white"><b style="font-size: large">WICHTIGE MITTEILUNG</b><br><br>' + this.announcementMsg + '</span>', {
            timeout: 1000 * Number(process.env.VUE_APP_SOSE_ANNOUNCEMENT_DISPLAY_TIMEOUT_SECONDS),
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            position: 'centerTop',
            style: 'dark',
            buttons: [
              {
                text: 'Später erinnern', action: (toast) => {
                  sessionStorage.setItem('sose.announcementMsg.showAgain', 'true');
                  this.$snotify.remove(toast.id);
                }
              },
              {
                text: 'Verstanden', action: (toast) => {
                  sessionStorage.setItem('sose.announcementMsg.showAgain', 'false');
                  this.$snotify.remove(toast.id);
                },
                bold: true
              },
            ]
          });
        }
      },
      version() {
        if (this.version && this.isAuthenticated) {
          this.$store.commit('versionMsg', '');

          this.$snotify.html('<span style="color: white"><b style="font-size: large">NEUE Software Version</b><br><br>' + this.versionMsg + '</span>', {
            timeout: 60000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'centerBottom',
            style: 'dark',
            buttons: [
              {
                text: 'SoSE empfehlen', action: (toast) => {
                  document.getElementById('recommendation-button-id').click();
                  this.$snotify.remove(toast.id);
                }
              },
              {
                text: 'Hinweis gelesen', action: (toast) => {
                  localStorage.setItem(this.user.md5 + '@Version' + this.appVersion.frontend.version, 'acknowledged')
                  this.$snotify.remove(toast.id);
                }
              },
            ]
          });
        }
      },
    },

    mounted() {
      this.$snotify.setDefaults({
        global: {
          newOnTop: false,
          maxAtPosition: 8,
          maxOnScreen: 8,
          oneAtTime: false,
          preventDuplicates: false,
        }
      });

      window.addEventListener('focus', () => this.windowActive = true, { passive: true });
      window.addEventListener('blur', () => this.windowActive = false, { passive: true });
    },

    data() {
      return {
        windowActive: true,
      }
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated;
      },
      appVersion() {
        return this.$store.getters.version;
      },
      user() {
        return this.$store.getters.user;
      },
    }
  }
</script>

<style lang="scss">
  @import "~vue-snotify/styles/dark";
  @import "~vue-snotify/styles/material";

  .snotify-simple {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .snotify-simple .snotifyToast__title,
  .snotify-simple .snotifyToast__body {
    color: #000; }

  .snotifyToast {
    max-height: 500px;
  }

  .snotifyToast__inner {
    width: 350px;
  }
</style>

