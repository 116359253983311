const routeOptions = [
  {
    path: '/',
    name: 'Home',
    componentName: 'Home'
  },
  {
    path: '/Drucken/Listen',
    name: 'PrintTable',
    componentName: 'CustomPrintTable',
  },
  {
    path: '/Drucken/Abrechnung',
    name: 'AccountingPrintTable',
    componentName: 'AccountingPrintTable',
  },
  {
    path: '/Dokumentation',
    name: 'Documentation',
    componentName: 'Documentation'
  },
  {
    path: '/Organisationen',
    name: 'Organizations',
    componentName: 'Organizations'
  },
  {
    path: '/Einrichtungen',
    name: 'FacilitiesManagement',
    componentName: 'Facilities'
  },
  {
    path: '/Kinder',
    name: 'Children',
    componentName: 'Children'
  },
  {
    path: '/Kinder/:id',
    name: 'ChildrenId',
    componentName: 'Children'
  },
  {
    path: '/Anwesenheit/Kinder',
    name: 'ChildrenPresence',
    componentName: 'ChildrenPresence'
  },
  {
    path: '/Gruppen',
    name: 'Groups',
    componentName: 'Groups'
  },
  {
    path: '/Eltern',
    name: 'Parents',
    componentName: 'Parents'
  },
  {
    path: '/Eltern/:id',
    name: 'ParentsId',
    componentName: 'Parents'
  },
  {
    path: '/Finanzen/Abrechnungen',
    name: 'Billings',
    componentName: 'Billings'
  },
  {
    path: '/Finanzen/Steuerbescheinigungen',
    name: 'TaxCertificates',
    componentName: 'TaxCertificates'
  },
  {
    path: '/Finanzen/Preislisten',
    name: 'PriceLists',
    componentName: 'PriceLists'
  },
  {
    path: '/Finanzen/Gebuehrenvorlagen',
    name: 'FeeTemplates',
    componentName: 'FeeTemplates'
  },
  {
    path: '/Finanzen/Bezirksrechnung',
    name: 'DistrictBill',
    componentName: 'DistrictBill'
  },
  {
    path: '/Benutzer',
    name: 'UsersManagement',
    componentName: 'Users'
  },
  {
    path: '/Mein-Konto',
    name: 'UserAccount',
    componentName: 'User'
  },
  {
    path: '/Verwaltung/Traeger',
    name: 'Organization',
    componentName: 'Organization'
  },
  {
    path: '/Verwaltung/Traeger/Vertrag',
    name: 'OrganizationContract',
    componentName: 'Organization'
  },
  {
    path: '/Verwaltung/Traeger/Rechnungen',
    name: 'OrganizationSettlements',
    componentName: 'Organization'
  },
  {
    path: '/Verwaltung/Einrichtungen',
    name: 'OrganizationFacilities',
    componentName: 'Facilities'
  },
  {
    path: '/Verwaltung/Einrichtungen/:facilityRefNo',
    name: 'OrganizationFacilitiesRefNo',
    componentName: 'Facilities'
  },
  {
    path: '/Verwaltung/Einrichtung/Import',
    name: 'FacilityImport',
    componentName: 'FacilityImport'
  },
  {
    path: '/Verwaltung/Einrichtung/Vorlagen',
    name: 'FacilityTemplates',
    componentName: 'FacilityTemplates'
  },
  {
    path: '/Verwaltung/Anpassungen',
    name: 'Customizations',
    componentName: 'Customizations'
  },
  {
    path: '/Verwaltung/Schliesszeiten',
    name: 'ClosingTimes',
    componentName: 'ClosingTimes'
  },
  {
    path: '/Verwaltung/Mitarbeiter',
    name: 'Employees',
    componentName: 'Employees'
  },
  {
    path: '/Verwaltung/Mitarbeiter/:id',
    name: 'EmployeesId',
    componentName: 'Employees'
  },
  {
    path: '/Verwaltung/Benutzer',
    name: 'Users',
    componentName: 'Users'
  },
  {
    path: '/Verwaltung/Benutzer/:id',
    name: 'UsersId',
    componentName: 'Users'
  },
  {
    path: '/Nachrichten',
    name: 'Messages',
    componentName: 'Messages'
  },
  {
    path: '/Verwaltung/Datenexport',
    name: 'DataExport',
    componentName: 'DataExport'
  },
  {
    path: '/Login',
    name: 'Login',
    componentName: 'Login'
  },
  {
    path: '/Login/:magic',
    name: 'LoginMagic',
    componentName: 'Login'
  },
  {
    path: '/Passwort/Vergessen',
    name: 'PasswordForgotten',
    componentName: 'PasswordForgotten'
  },
  {
    path: '/Passwort/Setzen/:token',
    name: 'PasswordReset',
    componentName: 'PasswordReset'
  },
  {
    path: '/Logout',
    name: 'Logout',
    componentName: 'Logout'
  },
  {
    path: '/Sage/Kunden',
    name: 'Customers',
    componentName: 'Customers'
  },
  {
    path: '/Sage/Rechnungen',
    name: 'Settlements',
    componentName: 'Settlements'
  },
  {
    path: '/Sage/Anfragen',
    name: 'TestLicenseInquiries',
    componentName: 'TestLicenseInquiries'
  },
  {
    path: '/Sage/Feedback',
    name: 'Feedback',
    componentName: 'Feedback'
  },
  {
    path: '/Preise',
    name: 'Prices',
    componentName: 'Registration'
  },
  {
    path: '/Kuendigung',
    name: 'Cancellation',
    componentName: 'Cancellation'
  },
  {
    path: '/Registrierung',
    name: 'Registration',
    componentName: 'Registration'
  },
  {
    path: '/Registrierung/:token',
    name: 'RegistrationToken',
    componentName: 'Registration'
  },
  {
    path: '/Registrierung/Test',
    name: 'RegistrationTest',
    componentName: 'Registration'
  },
  {
    path: '/Registrierung/Test/:token',
    name: 'RegistrationTestToken',
    componentName: 'Registration'
  },
  {
    path: '/Konto/Aktivierung/:token',
    name: 'AccountActivation',
    componentName: 'AccountActivation'
  },
  {
    path: '/Aktuelles/Eltern',
    name: 'ParentsNews',
    componentName: 'ParentsNews'
  },
  {
    path: '/Dokumente/Eltern',
    name: 'ParentsDocuments',
    componentName: 'ParentsDocuments'
  },
  {
    path: '/Verwaltung/Eltern',
    name: 'ParentsAdministration',
    componentName: 'ParentsAdministration'
  },
  { path: '*',
    name: 'NotFound',
    component: 'Registration',
  },
]

const routes = routeOptions.map(route => {
  return {
    ...route,
    component: () => import(/* webpackChunkcomponentName: "[request]" */ `./views/${route.componentName}.vue`)
  }
})

import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})