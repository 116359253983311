export default {

  name: 'TestAccountData',

  testAccountData: {
    organizationName: '',
    organizationType: '',
    email: '',
    password: '',
    passwordRepeat: '',
    advertisingToken: '',
    allowDataStorageDuringTestphase: false,
    allowContactDuringTestphase: false,
    allowDataStorageEnhancedDuration: false,
    allowContactEnhancedDuration: false,
  }
}