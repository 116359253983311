import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'FeedbackService',

  getFeedbackByType(type) {
    return HttpInterceptor.httpClient().get('sage/feedback/type/' + type);
  },

  getFeedbackByStatus(status) {
    return HttpInterceptor.httpClient().get('sage/feedback/status/' + status);
  },

  getFeedbackByPriority(priority) {
    return HttpInterceptor.httpClient().get('sage/feedback/priority/' + priority);
  },

  getAllFeedback() {
    return HttpInterceptor.httpClient().get('feedback/all');
  },

  updateFeedback(feedback) {
    return HttpInterceptor.httpClient().put('sage/feedback/update', feedback);
  },

  deleteFeedback(ticketNo) {
    return HttpInterceptor.httpClient().delete('sage/feedback/' + ticketNo + '/delete');
  },

  createFeedback(feedback) {
    return HttpInterceptor.httpClient().post('feedback/create', feedback);
  },
}