import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'InfoService',

  getVersion() {
    return HttpInterceptor.httpClient().get('infos/version');
  },

  consistencyCheck() {
    return HttpInterceptor.httpClient().get('infos/consistency-check');
  },

  getLabels() {
    return HttpInterceptor.httpClient().get('infos/labels');
  },

  getLabelsLight() {
    return HttpInterceptor.httpNoAuthClient().get('infos/labels/light');
  },

  getOverview() {
    return HttpInterceptor.httpClient().get('infos/overview');
  },

  getPreFilledContract(ai42ContractRequest, org) {
    if (org) {
      return HttpInterceptor.httpClient().post('infos/contract/org/prefilled', ai42ContractRequest);
    } else {
      return HttpInterceptor.httpNoAuthClient().post('infos/contract/prefilled', ai42ContractRequest);
    }
  },

  concludeContract(ai42ContractRequest, org) {
    if (org) {
      return HttpInterceptor.httpClient().post('infos/contract/org/conclude', ai42ContractRequest);
    } else {
      return HttpInterceptor.httpRecaptchaClient().post('infos/contract/conclude', ai42ContractRequest);
    }
  },

  getTemplateTags() {
    return HttpInterceptor.httpClient().get('infos/template/tags');
  },
}
