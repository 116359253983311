<template>
  <div v-if="isAuthenticated && labels">
    <md-button class="md-fab md-accent md-mini" style="margin-top: 14px;" @click="openDialog">
      <md-icon style="font-size: 1rem !important;">feedback</md-icon>
      <md-tooltip style="font-size: medium;">Feedback geben</md-tooltip>
    </md-button>

    <md-dialog :md-click-outside-to-close="false" v-if="labels.feedbackType" :md-active.sync="showDialog" @md-closed="closeDialog">
      <md-dialog-title>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-8 large-7" style="margin-top: -10px;">
            <h4 class="title">
              <md-icon>feedback</md-icon>&nbsp;<b>{{labels.feedbackType[feedback.type]}}</b>
            </h4>
          </div>
          <div class="cell small-12 medium-3 large-2" style="margin-top: -15px;">
            <SessionTime></SessionTime>
          </div>
          <div class="cell hide-for-small-only medium-1 large-3">
          </div>
        </div>
      </md-dialog-title>

      <md-dialog-content>
        <md-tabs :md-dynamic-height="true">
          <md-tab id="tab-create" md-label="Erstellen" style="padding: 16px 0 0 0;">
            <form novalidate @submit.prevent="validateBugReport">
              <div v-if="!reported">
                <FeedbackForm ref="feedbackForm" :feedback="feedback" :sending="sending" :mode="'add'"></FeedbackForm>
              </div>
              <div v-else class="grid-x grid-padding-x">
                <div class="cell text-center">
                  <br><br><br>
                  <h5>
                    <br>Die <b>Meldung</b> wurde <b>erfolgreich</b> übermittelt.<br>
                    <br>Vielen Dank für Deine Unterstützung!
                  </h5>
                </div>
              </div>

              <br>
              <md-progress-bar md-mode="indeterminate" v-if="sending"/>

              <md-card-actions>
                <div class="cell text-center">
                  <button v-if="!reported" class="button success" type="submit" :disabled="sending">
                    <i class="fi-megaphone"></i>&nbsp;&nbsp;&nbsp; {{labels.feedbackType[feedback.type]}} jetzt senden
                  </button>
                  <div v-else class="button success" @click="showDialog = false; reported = false;" :disabled="sending">
                    <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Fenster schliessen
                  </div>
                </div>
              </md-card-actions>

            </form>
          </md-tab>
          <md-tab id="tab-overview" md-label="Übersicht">

            <md-table v-model="searched" md-sort="name" md-sort-order="asc" md-card md-fixed-header style="height: 430px; width: 100%;">
              <md-table-toolbar>
                <div class="md-toolbar-section-start grid-x">
                  <div class="cell large-3 medium-4">
                    <p class="md-title ai-number">{{searched.length}} <span v-if="searched.length == 1">Eintrag</span><span v-else>Einträge</span></p>
                    <p v-if="allFeedback.length - searched.length > 0" style="color: crimson; margin: 0; padding-left: 10px;">{{allFeedback.length - searched.length}} nicht sichtbar
                      <md-tooltip style="font-size: medium; height: auto; white-space: pre-wrap;" md-direction="bottom" md-delay="500">
                        <b>Warum nicht sichtbar?</b><br><br>
                        <span>Durch setzen von Filtern wird die Liste eingeschränkt.</span><br>
                        <span>Wählen Sie z.B. <i style="color: greenyellow">+ Erledigte</i> um mehr Einträge zu sehen.</span>
                      </md-tooltip>
                    </p>
                    <p v-else style="color: seagreen; margin: 0; padding-left: 10px;">
                      Alle sichtbar
                    </p>
                  </div>
                  <div class="cell large-5 medium-8 hide-for-small-only text-left">
                    <div v-if="sending">
                      <vue-simple-spinner></vue-simple-spinner>
                    </div>
                    <div v-else>
                      <span>
                        <md-checkbox class="md-primary" v-model="withDone" @change="searchOnTable">+ Erledigte</md-checkbox>
                      </span>
                    </div>
                  </div>
                  <div class="cell large-4 medium-12 small-12">
                    <md-field md-clearable class="md-toolbar-section-end">
                      <md-input placeholder="Suche nach Titel ..." v-model="search" @input="searchOnTable"/>
                    </md-field>
                  </div>
                </div>
              </md-table-toolbar>

              <md-table-empty-state v-if="search"
                                    md-label="Keine passendes Feedback gefunden"
                                    :md-description="`Kein Feedback mit Titel '${search}' gefunden. Versuchen Sie es mit einem anderen Titel.`">
              </md-table-empty-state>

              <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item.id)">
                <md-table-cell md-label="Typ" md-sort-by="feeType">{{ labels.feedbackType[item.type] }}
                </md-table-cell>
                <md-table-cell md-label="Ticket-Nr." md-sort-by="ticketNo">{{ item.ticketNo }}
                </md-table-cell>
                <md-table-cell md-label="erstellt am" md-sort-by="createdDate">{{ item.createdDate | moment("L") }}
                </md-table-cell>
                <md-table-cell md-label="Titel" md-sort-by="title">{{ item.title }}
                  <md-tooltip style="font-size: medium; height: auto; width: 500px; white-space: pre-wrap;" md-direction="top">
                    <b>Beschreibung</b>:<br><br>
                    <span>{{item.text}}</span>
                  </md-tooltip>
                </md-table-cell>
                <md-table-cell md-label="Prio" md-sort-by="priority">{{ labels.feedbackPriority[item.priority] }}
                </md-table-cell>
                <md-table-cell md-label="Status" md-sort-by="status">{{ labels.feedbackStatus[item.status]  }}
                </md-table-cell>
              </md-table-row>
            </md-table>

          </md-tab>
        </md-tabs>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>
  import Feedback from '../../entities/Feedback';
  import FeedbackForm from '../../components/forms/FeedbackForm';
  import FeedbackService from '../../services/FeedbackService';
  import HttpErrorHandler from '../../services/HttpErrorHandler';
  import Spinner from 'vue-simple-spinner'
  import SessionTime from '../animations/SessionTime';

  import {validationMixin} from 'vuelidate'
  import {
    required,
  } from 'vuelidate/lib/validators'

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByTitle = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.title).includes(toLower(term)));
    }

    return items;
  };

  const searchMine = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.createdBy).includes(toLower(term)));
    }

    return items;
  };

  const removeByStatus = (items, term) => {
    if (term) {
      return items.filter(item => !toLower(item.status).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'BugReport',
    mixins: [validationMixin],

    components: {
      Feedback,
      FeedbackForm,
      'vue-simple-spinner': Spinner,
      SessionTime,
    },

    validations: {
      feedback: {
        type: {
          required,
        },
        title: {
          required,
        },
        text: {
          required,
        },
      }
    },

    mounted() {
      this.showDialog = false;
    },

    data() {
      return {
        showDialog: false,
        sending: false,
        reported: false,
        feedback: JSON.parse(JSON.stringify(Feedback.feedback)),

        allFeedback: [],
        searched: [],
        search: null,
        onlyMine: true,
        withDone: false,
      }
    },

    watch: {
      showDialog() {
        this.getAllFeedback();
      },
    },

    methods: {

      getAllFeedback() {
        this.sending = true;
        FeedbackService.getAllFeedback()
          .then(response => {
            this.allFeedback = response.data;
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Feedback-Übersicht ');
            this.sending = false;
          })
      },

      searchOnTable() {
        this.searched = searchByTitle(this.allFeedback, this.search);
        if (this.onlyMine) {
          this.searched = searchMine(this.searched, this.user.email);
        }
        if (!this.withDone) {
          this.searched = removeByStatus(this.searched, 'CLOSED');
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      reportBug(feedback) {
        this.sending = true;
        this.reported = false;
        FeedbackService.createFeedback(feedback)
          .then(response => {
            this.form = response.data;
            this.$refs.feedbackForm.reset();
            this.reported = true;
            this.sending = false;
            this.feedback = JSON.parse(JSON.stringify(Feedback.feedback));
            this.getAllFeedback();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Senden der Rückmeldung&nbsp;<b>' + this.feedback.title + '</b>') ;
          })
      },

      validateBugReport() {
        if (!this.reported) {
          this.$refs.feedbackForm.validateFeedback();

          if (!this.$refs.feedbackForm.invalid) {
            this.feedback.customerNo = this.organization.customerNo;
            this.reportBug(this.feedback);
          }
        }
      },

      openDialog() {
        this.showDialog = true;
      },

      closeDialog() {
        this.showDialog = false;
        this.reported = false;
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      organization() {
        return this.$store.getters.organization;
      },

      user() {
        return this.$store.getters.user;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated
      },
    }
  }
</script>

<style type="text/css" lang="scss" scoped>

  .md-dialog /deep/.md-dialog-container {
    min-width: 90%;
  }

  .md-dialog-content {
    max-width: 100%;
  }

  h4.title {
    color: dodgerblue;
    font-weight: 300;
    font-size: 1.4rem;
  }

  [type=button] {
    border-radius: 50%;
  }
</style>
