import Person from "./Person";
import BankAccount from "./BankAccount";

export default {

  name: 'Contract',

  contract: {
    account: BankAccount.bankAccount,
    contractReferenceNumber: '',
    contractDocumentsKey: null,
    documentAvailable: false,
    documentFileName: null,
    documentId: null,
    expired: false,
    paymentMethod: 'SEPA',
    refNr: 0,
    signingPerson: Person.person,
    signingPerson2: Person.person,
    secondSigningPerson: false,
    templateDocumentAvailable: false,
    templateFileName: null,
    contractFileName: null,
    templateId: null,
    validFrom: null,
    validUntil: null,
  }
}