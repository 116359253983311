<template>
  <nav>
    <div class="grid-x">
      <div class="cell large-8 medium-7 small-8">
        <ul class="menu dropdown" data-dropdown-menu>
          <li v-for="route in routes" :key="route.id">
            <router-link v-if="canSee(route)"
                         v-bind:key="route.id"
                         v-bind:to="route.path">
              <h3 v-if="isActive(route.path)" class="is-active-menu-item">{{ route.text }}</h3>
              <h3 v-else>{{ route.text }}</h3>
            </router-link>

            <ul v-if="route.subMenu.length > 0" class="vertical menu nested">
              <li v-for="subRoute in route.subMenu" :key="subRoute.id">
                <router-link v-if="canSee(subRoute)" class="menu-link"
                             v-bind:key="subRoute.id"
                             v-bind:to="subRoute.path">
                  <h4>{{ subRoute.text }}</h4>
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="isDevEnv"><a><h3 style="color: crimson;" class="text-right">&nbsp;&delta;</h3></a></li>
        </ul>
      </div>
      <div class="cell large-1 medium-1 small-1">
        <bugReport v-if="!isSage && !isParent"></bugReport>
      </div>
      <div class="cell large-1 medium-1 hide-for-small-only text-center">
        <SessionTime style="margin-top: 10px;" :navigation="true"></SessionTime>
      </div>
      <div class="cell large-2 medium-3 small-3 container">
        <div v-if="isAuthenticated" v-on:click="logout" class="button secondary logout">
          <i class="fi-torso"></i>&nbsp;<i class="fi-x"></i><span
            class="hide-for-small-only">&nbsp;&nbsp;&nbsp;Abmelden</span>
        </div>
        <div v-if="!isAuthenticated && !isActive('/Login')" v-on:click="login" class="button logout">
          <i class="fi-torso"></i>&nbsp;<i class="fi-key"></i><span class="hide-for-small-only">&nbsp;&nbsp;&nbsp;Anmelden</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import BugReport from './fabs/BugReport';
import SessionTime from './animations/SessionTime';
import jQuery from "jquery";

export default {
  name: 'Navigation',

  components: {
    bugReport: BugReport,
    SessionTime,
  },

  props: {
    currentPath: {
      type: String,
      required: true
    }
  },

  created() {
    this.$store.commit('navigationRoutes', this.routes);
  },

  mounted() {
    setTimeout(() => {
      this.toggleMenuStyle(this.isParent);
    }, 200);
  },

  watch: {
    isParent() {
      this.toggleMenuStyle(this.isParent);
    }
  },

  data() {
    return {
      routes: [
        {
          id: 0,
          text: 'SoSE',
          path: '/',
          roles: [
            'role.visitor',
            'role.sage',
            'role.admin',
            'role.treasurer.readwrite',
            'role.treasurer.read',
            'role.facility.readwrite',
            'role.facility.read',
            'role.hrmanager.read',
            'role.hrmanager.readwrite'],
          subMenu: []
        },
        {
          id: 1,
          text: 'Kinder',
          path: '',
          roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read',
            'role.treasurer.readwrite', 'role.treasurer.read',
            'role.documentation.read', 'role.documentation.readwrite'],
          subMenu: [{
            id: 11,
            text: 'Übersicht',
            path: '/Kinder',
            roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read',
              'role.treasurer.readwrite', 'role.treasurer.read',
              'role.documentation.read', 'role.documentation.readwrite'],
            subMenu: []
          },
            {
              id: 12,
              text: 'Anwesenheit',
              path: '/Anwesenheit/Kinder',
              roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read',
                      'role.treasurer.readwrite', 'role.treasurer.read'],
              subMenu: []
            },
            {
              id: 13,
              text: 'Gruppen',
              path: '/Gruppen',
              roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read'],
              subMenu: []
            },]
        },
        {
          id: 2,
          text: 'Eltern',
          path: '',
          beta: true,
          roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read'],
          subMenu: [{
            id: 11,
            text: 'Übersicht',
            path: '/Eltern',
            beta: true,
            roles: ['!role.sage', 'role.admin'],
            subMenu: []
          },
            {
              id: 12,
              text: 'Aktuelles',
              path: '/Aktuelles/Eltern',
              beta: true,
              roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read'],
              subMenu: []
            },
            {
              id: 13,
              text: 'Dokumente',
              path: '/Dokumente/Eltern',
              beta: true,
              roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read'],
              subMenu: []
            },]
        },
        {
          id: 3,
          text: 'Finanzen',
          path: '',
          roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          subMenu: [{
            id: 31,
            text: 'Abrechnungen',
            path: '/Finanzen/Abrechnungen',
            roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          }, {
            id: 32,
            text: 'Steuerbescheinigungen',
            path: '/Finanzen/Steuerbescheinigungen',
            roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          }, {
            id: 33,
            text: 'Preislisten',
            path: '/Finanzen/Preislisten',
            roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          }, {
            id: 34,
            text: 'Gebührenvorlagen',
            path: '/Finanzen/Gebuehrenvorlagen',
            roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          }, {
            id: 35,
            text: 'Bezirksrechnung',
            path: '/Finanzen/Bezirksrechnung',
            roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          }]
        },
        {
          id: 4,
          text: 'Organisationen',
          path: '/Organisationen',
          roles: ['role.sage'],
          subMenu: []
        },
        {
          id: 5,
          text: 'Einrichtungen',
          path: '/Einrichtungen',
          roles: ['role.sage'],
          subMenu: []
        },
        {
          id: 6,
          text: 'Benutzer',
          path: '/Benutzer',
          roles: ['role.sage'],
          subMenu: []
        },
        {
          id: 8,
          text: 'Verwaltung',
          path: '',
          roles: ['!role.sage', 'role.admin', 'role.deleted',
            'role.facility.readwrite', 'role.facility.read',
            'role.hrmanager.read', 'role.hrmanager.readwrite',
            'role.treasurer.read', 'role.treasurer.readwrite'],
          subMenu: [{
            id: 81,
            text: 'Träger',
            path: '/Verwaltung/Traeger',
            roles: ['!role.sage', 'role.admin', 'role.management', 'role.deleted',
                    'role.treasurer.read', 'role.treasurer.readwrite'],
          }, {
            id: 82,
            text: 'Einrichtungen',
            path: '/Verwaltung/Einrichtungen',
            roles: ['!role.sage', 'role.admin',
              'role.facility.readwrite', 'role.facility.read',
              'role.treasurer.read', 'role.treasurer.readwrite'],
          }, {
            id: 83,
            text: 'Schließzeiten',
            path: '/Verwaltung/Schliesszeiten',
            roles: ['!role.sage', 'role.admin',
              'role.facility.readwrite', 'role.facility.read',
              'role.treasurer.read', 'role.treasurer.readwrite',
              'role.hrmanager.read', 'role.hrmanager.readwrite'],
          }, {
            id: 84,
            text: 'Mitarbeiter',
            path: '/Verwaltung/Mitarbeiter',
            roles: ['!role.sage', 'role.admin',
              'role.hrmanager.read', 'role.hrmanager.readwrite',
              'role.treasurer.read', 'role.treasurer.readwrite'],
          }, {
            id: 86,
            text: 'Benutzer',
            path: '/Verwaltung/Benutzer',
            roles: ['!role.sage', 'role.admin'],
          }, {
            id: 87,
            text: 'Druckvorlagen',
            path: '/Verwaltung/Einrichtung/Vorlagen',
            roles: ['!role.sage', 'role.admin', 'role.treasurer.readwrite', 'role.treasurer.read'],
          }, {
            id: 88,
            text: 'Anpassungen',
            path: '/Verwaltung/Anpassungen',
            roles: ['!role.sage', 'role.admin', 'role.facility.readwrite', 'role.facility.read'],
          }, {
            id: 89,
            text: 'Daten-Export',
            path: '/Verwaltung/Datenexport',
            roles: ['!role.sage', 'role.admin',
              'role.treasurer.readwrite'],
          }, {
            id: 90,
            text: 'Daten-Import',
            path: '/Verwaltung/Einrichtung/Import',
            roles: ['!role.sage', 'role.admin'],
          },]
        },
        {
          id: 10,
          text: 'Sage',
          path: '',
          roles: ['role.sage'],
          subMenu: [{
            id: 101,
            text: 'Kunden',
            path: '/Sage/Kunden',
            roles: ['role.sage'],
          }, {
            id: 102,
            text: 'Rechnungen',
            path: '/Sage/Rechnungen',
            roles: ['role.sage'],
          }, {
            id: 103,
            text: 'Anfragen',
            path: '/Sage/Anfragen',
            roles: ['role.sage'],
          }, {
            id: 104,
            text: 'Feedback',
            path: '/Sage/Feedback',
            roles: ['role.sage'],
          }]
        },
        {
          id: 20,
          text: 'Aktuelles',
          path: '/Aktuelles/Eltern',
          roles: ['role.parent.read', 'role.parent.readwrite'],
          subMenu: []
        },
        {
          id: 21,
          text: 'Dokumente',
          path: '/Dokumente/Eltern',
          roles: ['role.parent.read', 'role.parent.readwrite'],
          subMenu: []
        },
        {
          id: 22,
          text: 'Verwaltung',
          path: '/Verwaltung/Eltern',
          roles: ['role.parent.read', 'role.parent.readwrite'],
          subMenu: []
        },
      ]
    }
  },

  computed: {

    isAuthenticated() {
      return this.$store.getters.authenticated
    },

    session() {
      return this.$store.getters.session;
    },

    user() {
      return this.$store.getters.user;
    },

    isBeta() {
      return this.$store.getters.beta;
    },

    token() {
      return sessionStorage.getItem('sose.access_token');
    },

    isDevEnv() {
      return process.env.NODE_ENV === 'development';
    },

    isSage() {
      return this.$store.getters.sage;
    },

    isParent() {
      return this.$store.getters.parent;
    },
  },

  methods: {

    toggleMenuStyle(toggle) {
      if (toggle) {
        jQuery('.menu.dropdown').addClass('parents-menu');
      } else {
        jQuery('.menu.dropdown').removeClass('parents-menu');
      }
    },

    login() {
      this.$router.push('/Login');
    },

    logout() {
      this.$store.commit('logout');
      this.$router.push('/Logout');
    },

    canSee(route) {
      if (route.beta) {
        if (!this.isBeta) {
          return false;
        }
      }

      const user = this.$store.getters.user;
      for (let userRole of user.roles) {
        for (let routeRole of route.roles) {
          if (routeRole.startsWith('!')) {
            if (userRole === routeRole.substring(1)) {
              return false;
            }
          } else {
            if (userRole === routeRole) {
              return true;
            }
          }
        }
      }
      return false;
    },

    isActive(path) {
      return (this.currentPath === path);
    }
  },
}
</script>

<style lang="scss" scoped>

.is-dropdown-submenu {
  z-index: 5;
}

.md-theme-dark .vertical.menu.nested {
  border: 1px solid #9d9d9d;
  background-color: #9d9d9d;
}

.menu a {
  transition: background 0.25s ease-in-out;
  background: none;
  text-decoration: none;
}

.menu a:hover, .dropdown.menu > li.is-active > a {
  transition: background 0.25s ease-in-out;
  background: #e4f5ef;
  text-decoration: underline;
  text-decoration-color: black;
}

.md-theme-dark .menu a:hover, .md-theme-dark .dropdown.menu > li.is-active > a {
  background: #626262;
  text-decoration: underline;
  text-decoration-color: aliceblue;
}

.parents-menu a:hover, .dropdown.parents-menu > li.is-active > a {
  background: #e4eef5 !important;
}

.md-theme-dark .parents-menu a:hover, .md-theme-dark .dropdown.parents-menu > li.is-active > a {
  background: #626262 !important;
}

.menu h3 {
  font-weight: 300;
  color: black;
  text-underline-color: black;
  text-decoration-color: black;
}

.menu h4 {
  font-weight: 300;
  font-size: 23px;
  color: black;
  text-underline-color: black;
  text-decoration-color: black;
}

.md-theme-dark .menu h3 {
  font-weight: 300;
  color: aliceblue;
  text-underline-color: aliceblue;
  text-decoration-color: aliceblue;
}

.md-theme-dark .menu h4 {
  font-weight: 300;
  font-size: 23px;
  color: aliceblue;
  text-underline-color: aliceblue;
  text-decoration-color: aliceblue;
}

.menu-link {
  padding-top: 8px !important;
  padding-bottom: 6px !important;
}

.is-active-menu-item {
  text-decoration: underline;
}

.logout {
  margin-right: 1rem;
  display: inline;
}

.container {
  margin-top: 1.5rem;
  text-align: right;
}

@media only screen and (max-width: 639px) {
  .container {
    margin-top: 1rem;
    text-align: right;
  }
}

.remaining-time {
  color: darkcyan;
}

.remaining-time-critical {
  color: crimson !important;
  opacity: 1 !important;
  animation-duration: 2s;
  animation-delay: 3s;
  animation-name: flashActive;
  animation-iteration-count: 10000000;
}

@keyframes flashActive {
  0% {
    font-size: 14px !important;
    opacity: 1;
  }

  50% {
    font-size: 24px !important;
    opacity: 0;
  }

  100% {
    font-size: 14px !important;
    opacity: 1;
  }
}
</style>
