import Address from "../entities/Address"
import Contact from "../entities/Contact"

export default {

  name: 'Person',

  person: {
    address: Address.address,
    birthdate: null,
    childRelation: 'NONE',
    clubMember: false,
    contact: Contact.contact,
    custody: false,
    dsgvoConfirmationObtained: false,
    firstname: '',
    fullName: '',
    lastname: '',
    nationality: 'DE',
    originCountry: 'DE',
    pickUpEntitled: false,
    portalLoginAllowed: false,
    portalLoginPossible: false,
    profession: '',
    salutation: '',
    selfServiceAllowed: false,
    sex: 'MALE',
    singleParent: false,
    soleCustody: false,
    title: '',
  }
}