<template>
  <div class="text-center">
    <md-button v-if="isAuthenticated" @click="refreshSession()"
               :disabled="criticalTimeLimitSeconds < remainingTimeSeconds">
      <h6 :class="remainingTimeClass">{{remainingTime | moment("mm:ss") }}</h6>
      <md-tooltip v-if="webAppConfig.soseTokenRefreshDisabled" md-direction="bottom" style="font-size: medium;">
        Wegen geplanter Wartungsarbeiten kann die Session derzeit nicht verlängert werden.
      </md-tooltip>
      <md-tooltip v-else md-direction="bottom" style="font-size: medium;">Session verlängern</md-tooltip>
    </md-button>
    <md-progress-bar v-if="isAuthenticated && criticalTimeLimitSeconds >= remainingTimeSeconds"
                     class="md-accent" md-mode="determinate" :md-value="remainingTimePercentage">
    </md-progress-bar>
    <br v-if="!navigation">
  </div>
</template>

<script>
  import jQuery from 'jquery';
  import HttpInterceptor from "../../services/HttpInterceptor";
  import Vue from 'vue';

  export default {
    name: 'SessionTime',

    created() {

      this.timeRefreshIntervalId = setInterval(() => {
        if (this.session.start && this.isAuthenticated) {
          this.remainingTimeSeconds = Number(this.session.refreshTokenExpiresIn) + 1; // Should be initially greater than criticalTimeLimitSeconds
          this.criticalTimeLimitSeconds = Number(this.session.refreshTokenExpiresIn) - Number(this.session.accessTokenExpiresIn); // 5 minutes refresh token life time

          let now = Vue.moment();
          let totalSessionTimeSeconds = Number(this.criticalTimeLimitSeconds) + Number(this.session.accessTokenExpiresIn);
          this.lifeTime = Vue.moment.utc(now.diff(this.session.start));
          this.remainingTimeSeconds = Math.round(((totalSessionTimeSeconds * 1000) - now.diff(this.session.start)) / 1000);
          this.remainingTime = Vue.moment.utc((totalSessionTimeSeconds * 1000) - now.diff(this.session.start));
          this.remainingTimePercentage = 100 * Number(this.remainingTimeSeconds / this.criticalTimeLimitSeconds);

          if (this.criticalTimeLimitSeconds < this.remainingTimeSeconds) {
            this.refreshTriggered = false;
          }
        }

        if (this.remainingTimeSeconds <= 0) {
          if (this.isAuthenticated) {
            this.remainingTimeSeconds = 0;
            this.remainingTime = Vue.moment.utc(0);
            this.logout();
          }
        }
      }, 1000);

      setTimeout(() => {
        jQuery('#app').bind('mousemove', this.autoRefreshSession);
        jQuery('.md-dialog-container').bind('mousemove', this.autoRefreshSession);
      }, 1000);
    },

    beforeDestroy() {
      clearInterval(this.timeRefreshIntervalId);
    },

    data() {
      return {
        timeRefreshIntervalId: 0,
        lifeTime: null,
        remainingTime: null,
        remainingTimePercentage: 100,
        remainingTimeSeconds: 101, // Should be initially greater than criticalTimeLimitSeconds
        criticalTimeLimitSeconds: 100, // 5 minutes refresh token life time
        refreshTriggered: false,
      }
    },

    props: {
      navigation: {
        required: false,
      },
    },

    methods: {

      autoRefreshSession() {
        if (this.isAuthenticated && !this.refreshTriggered && (this.criticalTimeLimitSeconds >= this.remainingTimeSeconds)) {
          this.refreshTriggered = true;
          this.refreshSession();
        }
      },

      refreshSession() {
        HttpInterceptor.doRefreshToken();
      },

      logout() {
        this.$store.commit('logout');
        this.$router.push('/Logout');
      },
    },

    computed: {

      remainingTimeClass() {
        if (this.remainingTimeSeconds > this.criticalTimeLimitSeconds) {
          if (this.navigation === true) {
            return 'remaining-time';
          } else {
            return 'remaining-time-hidden';
          }
        } else {
          return 'remaining-time-critical';
        }
      },

      webAppConfig() {
        return this.$store.getters.webAppConfig;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      session() {
        return this.$store.getters.session;
      },

      user() {
        return this.$store.getters.user;
      },

      token() {
        return sessionStorage.getItem('sose.access_token');
      },

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },

      isSage() {
        return this.$store.getters.sage;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .remaining-time {
    color: darkcyan;
  }

  .md-theme-dark .remaining-time {
    color: #83e3e3;
  }

  .remaining-time-hidden {
      // display: none;
    color: darkcyan;
  }

  .md-theme-dark .remaining-time-hidden {
      // display: none;
    color: #83e3e3;
  }

  .remaining-time-critical {
    color: crimson !important;
    opacity: 1 !important;
    animation-duration: 2s;
    animation-delay: 3s;
    animation-name: flashActive;
    animation-iteration-count: 10000000;
  }

  .md-theme-dark .remaining-time-critical {
    color: #af1919 !important;
  }

  @keyframes flashActive {
    0% {
      font-size: 14px !important;
      opacity: 1;
    }

    50% {
      font-size: 24px !important;
      opacity: 0;
    }

    100% {
      font-size: 14px !important;
      opacity: 1;
    }
  }
</style>

