import { render, staticRenderFns } from "./ReleaseUpdate.vue?vue&type=template&id=95e13d58&scoped=true&"
import script from "./ReleaseUpdate.vue?vue&type=script&lang=js&"
export * from "./ReleaseUpdate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e13d58",
  null
  
)

export default component.exports