import jQuery from "jquery";

export default {

  name: 'HttpErrorHandler',

  mainVue: null,

  handleError(error, main, msg) {
    this.mainVue = main;

    let soseErrorMsg = '';
    let unauthenticatedMessage = false;

    let errorMessage = {};
    if (this.mainVue.$store.getters.labels && this.mainVue.$store.getters.labels.errorMessage) {
      errorMessage = this.mainVue.$store.getters.labels.errorMessage;
    }

    let error_code = '';
    let error_message = '';
    let error_status = '';
    if (error && error.response && error.response.data) {

      if (error.response.data.error) {
        error_code = error.response.data.error;
        error_message = error.response.data.message;
        error_status = error.response.data.status;
      } else if (error.response.data.success === false) {
        error_code = error.response.data.origin;
        error_message = error.response.data.status;
      }
    }

    console.log('handleError() - msg : ' + msg)
    console.log('          component : ' + this.mainVue.$options.name)
    console.log('         error_code : ' + error_code)
    console.log('       error_status : ' + error_status)
    console.log('      error_message : ' + error_message)

    if (error_code === 'invalid_token') {
      soseErrorMsg = 'Session abgelaufen';
      unauthenticatedMessage = true;
      localStorage.setItem('sose.invalid_token', 'invalid_token');
      this.mainVue.$router.push('/Logout');
    } else if (error_code === 'Not Found') {
      soseErrorMsg = 'Service nicht verfügbar';
    } else if (['invalid_grant', 'Unauthorized', 'Forbidden', 'Internal Server Error',
        'Bad Request', 'VALIDATION_FAILED'].includes(error_code) ||
      (('Unprocessable Entity').includes(error_code) && (this.isAuthenticated() === false))) {
      unauthenticatedMessage = true;
      soseErrorMsg = msg;
    } else if (error_code === 'Internal Server Error') {
      soseErrorMsg = error.response.data.message;
    } else {
      if (errorMessage[error_code]) {
        unauthenticatedMessage = true;
        soseErrorMsg = errorMessage[error_code];
      } else {

        if (error_code && error_message) {
          soseErrorMsg = error_message;
        } else if (error_code && error_status) {
          soseErrorMsg = 'error: ' + error_code + ' - status: ' + error_status;
        } else {
          soseErrorMsg = 'Beim Ausführen der Anfrage ist ein Fehler aufgetreten.';
        }
      }
    }

    if (this.isAuthenticated() === true && msg) {
      let errorMsg = msg + '<br><b>' + soseErrorMsg + '</b>';
      this.mainVue.$store.commit('errorMsg', errorMsg);
    }

    if (this.isAuthenticated() === false && unauthenticatedMessage && soseErrorMsg) {
      let errorMsg = '<b>' + soseErrorMsg + '</b>';
      this.mainVue.$store.commit('errorMsg', errorMsg);
    }
  },

  isAuthenticated() {
    return this.mainVue.$store.getters.authenticated;
  },

  maintainDarkMode(main, delay) {
    this.mainVue = main;
    if (!delay) {
      delay = 1000;
    }
    setTimeout(() => {
      if (this.mainVue.$store.getters.darkMode) {
        jQuery('.md-theme-default').removeClass('md-theme-default').addClass('md-theme-dark');
      } else {
        jQuery('.md-theme-dark').removeClass('md-theme-dark').addClass('md-theme-default');
      }
    }, delay);
  }
}

