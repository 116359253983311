<template>
  <div>
  </div>
</template>

<script>
  import ContentService from "../../services/ContentService";

  export default {
    name: 'AppConfiguration',

    created() {
      this.getAppConfiguration();

      this.configurationRefreshIntervalId = setInterval(() => {
        this.getAppConfiguration();
      }, 1000 * Number(process.env.VUE_APP_SOSE_CONFIGURATION_REFRESH_INTERVAL_SECONDS));
    },

    mounted() {
      window.addEventListener('focus', () => this.windowActive = true, { passive: true });
      window.addEventListener('blur', () => this.windowActive = false, { passive: true });
    },

    beforeDestroy() {
      clearInterval(this.configurationRefreshIntervalId);
    },

    data() {
      return {
        configurationRefreshIntervalId: 0,
        windowActive: true,
      }
    },

    methods: {

      getAppConfiguration() {
        if (!this.windowActive) {
          return;
        }
        ContentService.getContent(process.env.VUE_APP_SOSE_CONFIGURATION_WP_ID)
          .then(response => {
            this.$store.commit('webAppConfig', response.data.content.rendered);
          })
          .catch(() => {
            this.$store.commit('webAppConfig', null);
          });
      },
    },

    computed: {

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>

