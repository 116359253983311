export default {

  name: 'Address',

  address: {
    fullAddress: '',
    street: '',
    streetNumber: '',
    zipcode: '',
    placeOfResidence: '',
    country: 'DE',
    selectedCountry: '',
  }
}