<template>
  <div v-if="feedback" class="grid-x grid-padding-x">
    <div class="cell large-4 medium-12">
      <md-field :class="getValidationClass('feedbackType')">
        <label>Was soll gemeldet werden?</label>
        <md-select v-model="feedback.type" autocomplete="off"
                   :disabled="sending">
          <md-option v-for="feedbackType of labels.feedbackTypes" :value="feedbackType" :key="feedbackType">
            {{labels.feedbackType[feedbackType]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.feedback.type.required">Bitte wählen</span>
      </md-field>
    </div>

    <div class="cell large-8 medium-12">
      <md-field :class="getValidationClass('title')">
        <label for="title">Titel</label>
        <md-input name="title" id="title" v-model="feedback.title" :disabled="sending"/>
        <span class="md-error" v-if="!$v.feedback.title.required">Titel ist notwendig</span>
      </md-field>
    </div>

    <div v-if="isSage" class="cell large-6 medium-12">
      <md-field :class="getValidationClass('status')">
        <label>Status</label>
        <md-select v-model="feedback.status" autocomplete="off"
                   :disabled="sending">
          <md-option v-for="feedbackStatus of labels.feedbackStatuss" :value="feedbackStatus" :key="feedbackStatus">
            {{labels.feedbackStatus[feedbackStatus]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.feedback.status.required">Bitte wählen</span>
      </md-field>
    </div>

    <div v-if="isSage" class="cell large-6 medium-12">
      <md-field :class="getValidationClass('priority')">
        <label>Priorität</label>
        <md-select v-model="feedback.priority" autocomplete="off"
                   :disabled="sending">
          <md-option v-for="feedbackPriority of labels.feedbackPriorities" :value="feedbackPriority"
                     :key="feedbackPriority">
            {{labels.feedbackPriority[feedbackPriority]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.feedback.priority.required">Bitte wählen</span>
      </md-field>
    </div>

    <div class="cell large-12 medium-12">
      <md-field :class="getValidationClass('priority')">
        <label for="text">Beschreibung</label>
        <md-textarea name="text" id="text" style="height: 200px; max-height: 200px; " v-model="feedback.text" :disabled="sending"/>
        <span class="md-error" v-if="!$v.feedback.text.required">Beschreibung ist notwendig</span>
      </md-field>
    </div>

  </div>
</template>

<script>

  import {validationMixin} from 'vuelidate'
  import {
    required,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'FeedbackFrom',
    mixins: [validationMixin],

    props: {
      feedback: {
        required: true
      },
      sending: {
        required: true
      },
      mode: {
        required: true,
      },
    },

    data: () => ({
      invalid: true,
    }),

    validations: {
      feedback: {
        type: {
          required,
        },
        priority: {
          required,
        },
        status: {
          required,
        },
        title: {
          required,
        },
        text: {
          required,
        },
      }
    },

    methods: {
      getValidationClass(fieldName) {
        let field = this.$v.feedback[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateFeedback() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },

      reset() {
        this.$v.$reset();
      }
    },

    computed: {
      labels() {
        return this.$store.getters.labels
      },

      isSage() {
        return this.$store.getters.sage;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>