export default {

  name: 'Contact',

  contact: {
    mobilePhone: '',
    phone: '',
    email: '',
    businessPhone: '',
    fax: ''
  }
}