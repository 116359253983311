<template>
  <div v-if="privacyStatement && isAuthenticated && !isSage">

<!--
    <md-button class="privacy" @click="showDialog = true"><h4 class="privacy">Datenschutzerklärung</h4></md-button>
-->
    <h4 class="product">
      <span style="font-size: 1.2rem; font-weight: 400; color: seagreen">
        <a href="https://www.ai42.de/release-notes/sose-release-notes" target="_blank">SoSE
              <md-tooltip>Mehr Informationen zu einzelnen Releases</md-tooltip></a>
      </span>
      ein Produkt der <a href="https://www.ai42.de" target="_blank">ai42</a>
    </h4>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDialog" class="container" @md-closed="showDialog = false">
      <md-dialog-title>
        <h4 @click="showDialog = true">
          <span>
            <md-icon style="margin-bottom: 5px;">lock</md-icon>
            &nbsp;Datenschutzerklärung
          </span>
        </h4>
      </md-dialog-title>

      <md-dialog-content v-if="organization && organization.address">

        <div class="row">
          <div class="cell">
            <h4>Verantwortlicher im Sinne der DSGVO:</h4>
            <p>Die/Der
              <i>{{organization.name}}, {{organization.address.fullAddress}}</i> (im Folgenden „der Träger“) ist als
              Anbieterin/Anbieter der Website
              <i>„SoSE des Trägers {{organization.name}}“</i> die/der Verantwortliche im Sinne des Datenschutzrechts
              (siehe Art. 3 Abs. 2 BayDSG<sup>1</sup>, Art. 4 Ziffer 7 DSGVO<sup>2</sup>)
              für die hier nachfolgend im Einzelnen dargestellten personenbezogenen Daten von Erziehungsberechtigten,
              Kindern, Mitarbeitern und des Verwaltungspersonals des Trägers und der dazugehörigen Einrichtungen.
            </p>
            <h4>Auftragsverarbeitung:</h4>
            <p>
              Für die technische Umsetzung, insbesondere die Überlassung der zum Betrieb der Website erforderlichen
              Software
              und der Wartung, bedient sich der Träger als Auftragnehmer der <i>ai42 UG (haftungsbeschränkt),
              Pirmasenserstr. 152, 90469 Nürnberg</i>,
              E-Mail: kontakt@ai42.de, Vertretungsberechtigte: Wolfgang Füssel und Torsten Hoffmann,
              Hompage: www.ai42.de. Letztere nutzt für die Serverinfrastruktur und deren Anbindung an das Internet die
              Leistungen
              der <i>STRATO AG, Pascalstraße 10, 10587 Berlin</i> als Nachunternehmer.
            </p>
            <p>
              Der Träger schützt die Privatsphäre und die privaten Daten der Website-Benutzer und der darin verwalteten
              Personen.
              Der Träger verarbeitet personenbezogenene Daten in Übereinstimmung mit dieser Datenschutz-Information
              und den geltenden Rechtsvorschriften insbesondere nach dem Telemediengesetz<sup>4</sup> - soweit jeweils
              einschlägig - in der aktuell gültigen Fassung.
            </p>
            <p>
              In dieser Datenschutzerklärung wird erläutert, welche personenbezogenen Daten verarbeitet werden.
            </p>
            <p>
              Der Träger bittet daher Sie als Erziehungsberechtigte, Mitarbeiter oder Verwaltungspersonal
              die nachfolgenden Ausführungen sorgfältig durchzulesen.
            </p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <h4>1. Erheben, Verarbeiten und Nutzung personenbezogener Daten</h4>
            <h5>1.1 Allgemeines</h5>
            <p>
              Personenbezogene Daten sind alle Informationen, die sich auf die Benutzerin/den Benutzer der Website
              aber auch die damit verwalteten Personen
              (Erziehungsberechtigte, Kinder, Mitarbeiter und Verwaltungspersonal des Trägers und der dazugehörigen
              Einrichtungen)
              beziehen (siehe auch Art. 4 Ziffer 1 DSGVO).
            </p>
            <h5>1.2 Datenverarbeitung im Zusammenhang mit der Nutzung der Website</h5>
            <p>
              Im Zusammenhang mit der Nutzung werden, soweit bekannt und erfasst, folgende personenbezogenen Daten
              verarbeitet:
            </p>
            <h5>1.2.1 Benutzer</h5>
            <p>
              Im Rahmen deren vorheriger Einwilligung werden im nicht automatisierten Verfahren folgende Daten erfasst
              und verarbeitet:
              <i>Vorname, Nachname, Email, Benutzer-Profil, Passwort, Kunden-Nummer, zugewiesende Einrichtungen</i> und
              die <i>Einwilligung zur Datenverarbeitung</i>.
              Jeder Benutzer hat im Bereich <i>Benutzer-Konto</i> die Möglichkeit, seine persönlichen Daten wie
              <i>Vorname, Nachname, Passwort</i> und die <i>Einwilligung zur Datenverarbeitung</i> selbst zu bearbeiten.
            </p>
            <h5>1.2.2 Erziehungsberechtigte</h5>
            <p>
              Im Rahmen deren vorheriger Einwilligung werden im nicht automatisierten Verfahren folgende Daten erfasst
              und verarbeitet:
              <i>Anrede, Vorname, Nachname, Titel, Geburtstag</i>.
              Adresse mit Angabe von <i>Staße, Hausnummer, Postleitzahl, Wohnort, Land</i>.
              Kontaktdaten mit Angabe von <i>Telefon-Nummer (mobil), Telefon-Nummer (Festnetz), EMail-Adresse,
              Fax-Nummer</i>.
              Merkmale mit Angabe von <i>Kindbeziehung (Vater, Mutter, Bruder, Schwester, etc.), Abholerlaubnis,
              alleinerziehend, alleiniges Sorgerecht, Beruf</i> und die <i>Einwilligung zur Datenverarbeitung</i>.
            </p>
            <h5>1.2.3 Kinder</h5>
            <p>
              Im Rahmen der vorherigen Einwilligung der Erziehungsberechtigten werden im nicht automatisierten Verfahren
              folgende Daten erfasst und verarbeitet:<br>
              <br>1.2.3.1 Stammdaten<br>
              <i>Vorname, Nachname, Geburtstag, Geschlecht</i> und die <i>Einwilligung zur Datenverarbeitung</i>.
              Adresse mit Angabe von <i>Staße, Hausnummer, Postleitzahl, Wohnort, Land</i>.
              Kontaktdaten mit Angabe von <i>Notfall-Rufnummer, Notfall-Ansprechpartner</i>.
              Allgemeine Merkmale mit Angabe von <i>Bereich, Förderkommune, Eintrittsdatum, Austrittsdatum</i>.
              Spezifische Merkmale mit <b>Ja-/Nein-Angabe</b> von <i>Behinderung, Migrationshintergrund, Mögliches
              Vorschulkind,
              Vorschulkind, Datenweitergabe-Erlaubnis, Geschwister, Wirtschaftliche Jugendhilfe</i>.
              Ergänzende Merkmale zum Migrationshintergrund wie <i>Herkunftsland</i> und <i>Muttersprache</i>.
              Merkmale zur Ernährung mit <b>Ja-/Nein-Angabe</b> von <i>Allergien, "Darf Schweinefleisch essen"</i>
              und genauer Angabe aller <i>Lebensmittel</i> mit allergisch bedingten Unverträglichkeiten.
              Zustimmungen mit <b>Ja-/Nein-Angabe</b> zu den folgenden Punkten: <i>Pflaster applizieren / Salbengabe,
              Fotonutzung für interne Zwecke,
              Fotonutzung für öffentliche Zwecke, Kontakt mit Tieren, Schwimmbadbesuch inkl. Schwimmen,
              Zeckenentfernung, Beförderung in Kraftfahrzeugen</i>.
              Systemdaten des KiBiG.web mit Angabe von <i>Id, Name</i>.<br>
              <br>1.2.3.2 Buchungszeiten<br>
              Detailierte Informationen zu den <i>Buchungszeiten</i> unter Angabe von <i>Buchungszeit-Typ, Bereich,
              Datum Gültig von,
              Datum Gültig bis</i>. Pro <i>Wochentag / Öffnungstag</i> der Einrichtung jeweils die <i>Startuhrzeit</i>
              und die <i>Endeuhrzeit</i> der Buchung.
              Weitere buchungsrelevante Merkmale wie <i>Behinderung, Vorkurs besucht, Förderkommune</i>.<br>
              <br>1.2.3.3 Gebühren<br>
              Eine Auflistung einzelner Gebühren mit jeweils Angabe von <i>Gebührenart, Zahlungsintervall, Datum Gültig
              von,
              Datum Gültig bis, Monat 1. Abbuchung, Basisbetrag, Gesamtbetrag, Ermäßigung, Nachlass</i>.<br>
              <br>1.2.3.4 Verträge<br>
              Eine Auflistung einzelner Verträge mit jeweils Angabe von <i>Vertragsnummer, Zahlungsmethode, Datum Gültig
              von,
              Datum Gültig bis</i>. Daten zum Vertragspartner jeweils mit Angabe von <i>Anrede, Titel, Vorname,
              Nachname, Einwilligung zur Datenverarbeitung</i> und
              Adresse mit Angabe von <i>Staße, Hausnummer, Postleitzahl, Wohnort, Land</i>.
              Daten zur Bankverbindung jeweils mit Angabe von <i>Kontoinhaber, Name der Bank, IBAN, BIC, Referenz
              Mandat, Datum der Mandatserteilung</i>.<br>
              <br>1.2.3.5 Dokumentation<br>
              Eine Auflistung einzelner Dokumentationen der Typen <i>Notiz, Beobachtung, Elterngespräch, Perik, Seldak,
              Sismik</i> mit spezifischen Angaben zum Kind.
              Jeder Dokumentationstyp enthält ein Deckblatt mit Angabe von <i>Name des Kindes, Alter des Kindes, Namen
              der Fachkräfte die hauptsächlich die Angaben erstellt haben,
              Datum Beginn der Bearbeitung, Datum Ende der Bearbeitung</i>. Beim Typ <i>Elterngespräch</i> erfolgt die
              zusätzliche Angabe von <i>
              Eltern und Verwandte, die bei dem Elterngespräch anwesend waren</i>.<br><br>
              Die Dokumentationen der Typen <i>Notiz, Beobachtung, Elterngespräch</i> bieten jeweils die Möglichkeit
              über Freitexteingaben <i>beliebige Informationen</i>
              (auch personenbezogene Daten) zu speichern. <span style="color: indianred">Für die <b>Inhalte</b> sind <b>ausschließlich die Einrichtungen mit ihren Fachkräften</b> verantwortlich</span>!<br><br>
              Die Dokumentationen der Typen <i>Perik, Seldak, Sismik</i> sind standardisierte Beobachtungsbögen des <i>Staatsinstitut
              für Frühpädagogik IFP</i>.
              Die entsprechenden Konzepte, Fragen und Inhalte zu den jeweiligen Bögen sind in ihren aktuellen Fassungen
              auf der Website des
              <a href="https://www.ifp.bayern.de/" target="_blank">Staatsinstitut für Frühpädagogik IFP</a> zu
              finden.
              Darüberhinaus bieten diese Bögen jeweils die Möglichkeit über Freitexteingaben <i>beliebige
              Informationen</i>
              (auch personenbezogene Daten) zu speichern. <span style="color: indianred">Für die <b>Inhalte</b> sind <b>ausschließlich die Einrichtungen mit ihren Fachkräften</b> verantwortlich</span>!<br>
            </p>
            <h5>1.2.4 Mitarbeiter</h5>
            <p>
              Im Rahmen deren vorheriger Einwilligung werden im nicht automatisierten Verfahren folgende Daten erfasst
              und verarbeitet:<br>
              <br>1.2.4.1 Stammdaten<br>
              <i>Anrede, Vorname, Nachname, Titel, Geburtstag</i> und die <i>Einwilligung zur Datenverarbeitung</i>.
              Adresse mit Angabe von <i>Staße, Hausnummer, Postleitzahl, Wohnort, Land</i>.
              Kontaktdaten mit Angabe von <i>EMail, Mobil Telefonnummer, Festnetz Telefonnummer, Faxnummer</i>.
              Systemdaten des KiBiG.web mit Angabe von <i>Id, Name</i>.
              Arbeitsdaten mit Angabe von <i>Einrichtung, Sozialversicherungs-Nr, Krankenversicherung, Monatliches
              Gehalt,
              Vertragsbeginn, Vertragsende, Befristung</i>.
              Meldedaten mit Angabe von <i>Bereich, Arbeitsbereich, Beruf, Qualifikation, Typ, Position</i>.<br>
              <br>1.2.4.2 Arbeitszeiten<br>
              Detailierte Informationen zu den <i>Arbeitszeiten</i> unter Angabe von <i>Bereich, Qualifikation, Datum
              Gültig von,
              Datum Gültig bis, Anzahl Wochenstunden</i>.<br>
            </p>

            <h5>1.3 Nutzungsdaten</h5>
            <p>
              1.3.1 Der Träger darf Nutzungsdaten (personenbezogene Daten einer Benutzerin/eines Benutzers,
              um die Inanspruchnahme von Telemedien zu ermöglichen) nur verarbeiten, soweit dies erforderlich ist.
              Nutzungsdaten sind insbesondere Daten zur Identifikation über den Nutzungszeitraum
              oder den konkreten Dienst.
            </p>
            <p>
              1.3.2 Zur systeminternen Identifikation der Benutzerin/des Benutzers werden <i>E-Mail-Adresse</i> und <i>Passwort</i>
              verwendet und verarbeitet.<br>
            </p>
            <p>
              1.3.3 In diesem Zusammenhang verarbeitet Der Träger personenbezogene Daten wie folgt:
              Informationen über den letzten Besuch der Website, um einen eventuellen Missbrauch
              der Zugangskennung anzuzeigen. Dies geschieht in der Regel durch die Speicherung von <i>Datum und Uhrzeit
              der aktuellen und der letzten Anmeldung</i>.
              Für die Nutzung der Website werden <a href="https://de.wikipedia.org/wiki/HTTP-Cookie" target="_blank">Cookies</a>,
              <a href="https://developer.mozilla.org/de/docs/Web/API/Window/localStorage"
                 target="_blank">Local-Storage</a> und
              <a href="https://developer.mozilla.org/de/docs/Web/API/Window/sessionStorage" target="_blank">Session-Storage</a>
              des Web-Browsers verwendet,
              um die Bedienung der Website zu erleichtern.
            </p>
            <p>
              1.3.4 Eine Zusammenführung von Nutzungsdaten verschiedener Telemediendienste erfolgt nicht.
            </p>
            <p>
              1.3.5 Eine Verwendung der Nutzungsdaten für Zwecke der Werbung, Marktforschung oder zur
              bedarfsgerechten Gestaltung der Telemedien erfolgt nicht.
            </p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <h4>2. Allgemeiner Verwendungszweck</h4>
            <p>
              Der Träger verwendet die personenbezogenen Daten der Benutzer, der Erziehungsberechtigten, der Kinder und der Mitarbeiter
              soweit in dieser Datenschutzerklärung nichts anderes bestimmt ist, ausschließlich zu folgenden Zwecken:
            </p>
            <h5>2.1. Daten der Benutzer</h5>
            <ul>
              <li>zur organisatorischen Unterstützung der zum Träger zugehörigen Einrichtungen durch:
                <ul type="disc">
                  <li>Bereitstellung eines personenbezogenen Benutzer-Zugangs mit Hilfe dessen
                  </li>
                  <li>ergänzende Bereitstellung von einrichtungsbezognen Informationen und
                  </li>
                  <li>einer ergänzenden Kommunikationsplattform zur Übermittlung von Informationen, die den Einrichtungsbetrieb
                    betreffen,
                  </li>
                  <li>sowie zur Unterstützung der Benutzerinnen, Benutzer und der Einrichtung bei der Abwicklung
                    alltäglicher Verwaltungsaufgaben.
                  </li>
                </ul>
              </li>
              <li>zur Sicherstellung, dass die Nutzung der Website sicher und möglichst effizient erfolgen kann.</li>
            </ul>
            <h5>2.2. Daten der Kinder</h5>
            <ul>
              <li>zur organisatorischen Unterstützung der zum Träger zugehörigen Einrichtungen durch:
                <ul>
                  <li>Erfassung, Verwaltung und Bereitstellung aller notwendigen Informationen eines Kindes mit deren Hilfe
                  </li>
                  <li>die Planung zur täglichen Betreuung, Versorgung, Erziehung des Kindes unterstützt und vereinfacht wird und
                  </li>
                  <li>darüber hinaus administrative Aufgaben und Pflichten einfacher, sicherer und effizienter erledigt werden können,
                  </li>
                  <li>sowie zur Unterstützung bei den behördlich vorgeschriebenen Meldepflichten zur Erfüllung gesetzlicher Auflagen und Erlangung möglicher Zuschüsse,
                  </li>
                  <li>als auch zur Erfüllung aller angeforderten Auflagen hinsichtlich Dokumentation und Archivierung.
                  </li>
                </ul>
              </li>
              <!--<li>zur Sicherstellung, dass die Daten der Kinder durch moderne digitale Methoden:
                <ul>
                  <li>sowohl durch geeignete Verschlüsselungsverfahren vor unbefugtem Zugriff,
                  </li>
                  <li>als auch vor Datenverlust durch regelmäßige Sicherungsverfahren absolut geschützt sind,
                  </li>
                  <li>sowie die automatisierte Löschung der Daten gemäß den Vorgaben der Datenschutzgrundverordnung durchgeführt wird.
                  </li>
                </ul>
              </li>-->
            </ul>
            <h5>2.3. Daten der Erziehungsberechtigten und Vertragspartner</h5>
            <ul>
              <li>zur organisatorischen Unterstützung der zum Träger zugehörigen Einrichtungen durch:
                <ul>
                  <li>Erfassung, Verwaltung und Bereitstellung aller notwendigen Informationen der Erziehungsberechtigten jedes Kindes mit deren Hilfe
                  </li>
                  <li>die eindeutige Zuordnung der unmittelbaren Ansprechpartner in Bezug auf Sorgerecht, Abholungsrecht und Entscheidungsrecht in Notfällen für jedes Kindes sichergestellt wird wird und
                  </li>
                  <li>darüber hinaus Involvierung der Erziehungsberechtigten bei Erfüllung aller angeforderten Auflagen hinsichtlich Dokumentation (z.B. Elterngespräch),
                  </li>
                  <li>sowie zur Unterstützung bei Vertragsfomalitäten zwischen den Einrichtungen und den Erziehungsberechtigten bzw. Vertragspartnern,
                  </li>
                  <li>als auch zur Unterstützung der Zahlungsabwicklung zwischen Einrichtung und Vertragspartner durch Nutzung des SEPA Lastschriftverfahrens.
                  </li>
                </ul>
              </li>
              <!--<li>zur Sicherstellung, dass die Daten der Erziehungsberechtigten und Vertragspartner durch moderne digitale Methoden:
                <ul>
                  <li>sowohl durch geeignete Verschlüsselungsverfahren vor unbefugtem Zugriff,
                  </li>
                  <li>als auch vor Datenverlust durch regelmäßige Sicherungsverfahren absolut geschützt sind,
                  </li>
                  <li>sowie die automatisierte Löschung der Daten gemäß den Vorgaben der Datenschutzgrundverordnung durchgeführt wird.
                  </li>
                </ul>
              </li>-->
            </ul>
            <h5>2.4. Daten der Mitarbeiter</h5>
            <ul>
              <li>zur organisatorischen Unterstützung der zum Träger zugehörigen Einrichtungen durch:
                <ul>
                  <li>Erfassung, Verwaltung und Bereitstellung aller notwendigen Informationen der Mitarbeiter mit deren Hilfe
                  </li>
                  <li>die Planung der Arbeitszeiten zur optimalen und angeforderten Versorgungskapazität unterstützt und vereinfacht wird und
                  </li>
                  <li>darüber hinaus administrative Aufgaben und Pflichten einfacher, sicherer und effizienter erledigt werden können,
                  </li>
                  <li>sowie zur Unterstützung bei den behördlich vorgeschriebenen Meldepflichten zur Erfüllung gesetzlicher Auflagen und Erlangung möglicher Zuschüsse,
                  </li>
                  <li>als auch zur Erfüllung aller angeforderten Auflagen hinsichtlich Dokumentation und Archivierung.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <h4>3. Nutzung spezieller Verfahren</h4>
            <h5>3.1 Serverseitiges Aufzeichnen von Informationen über Ihren Computer</h5>
            <p>
              Bei jedem Zugriff auf die Website verarbeitet der Träger automatisiert folgende Informationen
              über den jeweils zugreifenden Computer: Die <i>IP-Adresse</i> des Computers, die <i>Anfrage</i> des
              jeweiligen Browsers, sowie die <i>Zeit</i> dieser Anfrage. Die IP-Adresse des jeweiligen
              Computers wird dabei nur für die Zeit der jeweiligen Nutzung der Website gespeichert
              und im Anschluss daran unverzüglich gelöscht oder durch Kürzung anonymisiert.
              Die Daten werden nur für einen begrenzten Zeitraum, entsprechend der Regelungen des TMG, gespeichert.
            </p>
            <h5>3.2 Verwendung von <a href="https://de.wikipedia.org/wiki/HTTP-Cookie" target="_blank">Cookies</a>,
              <a href="https://developer.mozilla.org/de/docs/Web/API/Window/localStorage"
                 target="_blank">Local-Storage</a> und
              <a href="https://developer.mozilla.org/de/docs/Web/API/Window/sessionStorage" target="_blank">Session-Storage</a>
            </h5>
            <p>
              Der Träger verarbeitet außerdem Informationen über die Nutzung der Website durch die Verwendung
              sog. <a href="https://de.wikipedia.org/wiki/HTTP-Cookie" target="_blank">Cookies</a>,
              <a href="https://developer.mozilla.org/de/docs/Web/API/Window/localStorage"
                 target="_blank">Local-Storage</a> und
              <a href="https://developer.mozilla.org/de/docs/Web/API/Window/sessionStorage" target="_blank">Session-Storage</a>.
              Hierbei werden Informationen über den verwendeten Browser auf dem Datenträger
              des Computers zur Verbesserung des Nutzererlebnisses gespeichert.
              Diese enthalten ausschließlich bestimmte Einstellungen zur Verwendung der Website des jeweiligen Nutzers,
              um diesen bei erneuter Anmeldung den letzten Nutzungszustand der Website wieder herzustellen.
              Ein Cookie, ein Session-Storage und auch ein Local-Storage Eintrag enthält in der Regel den Namen der Domain,
              von der die Daten gesendet wurden sowie Informationen über das Alter der Daten.
            </p>
            <h5>3.3 Verwendung von aktiven Komponenten</h5>
            <p>
              3.3 In den verwendeten Cookies werden lediglich die oben erläuterten Daten über die Nutzung
              der Website gespeichert. Dies erfolgt nicht durch eine persönliche Zuordnung,
              sondern durch Zuweisung einer Identifikationsnummer zu dem Cookie („Cookie-ID“).
              Eine Zusammenführung der Cookie-ID mit dem jeweiligen Namen der Nutzerin oder des Nutzers,
              der jeweiligen IP-Adresse oder mit ähnlichen Daten, die eine Zuordnung des Cookies zu einer
              bestimmten Person ermöglichen würden, erfolgt nicht. Das Cookie gilt nur für die jeweilige
              Nutzungszeit der Website. Wie der Einsatz von Browser-Cookies unterbunden werden kann,
              wird unter Ziffer 3.6. erläutert.
            </p>
            <p>
              3.4 Die Website verwendet keine Tracking-Technologien.
            </p>
            <p>
              3.5 Die Website verwendet JavaScript.
            </p>
            <p>
              3.6 Sollten die Nutzerin/der Nutzer eine Verwendung von Browser-Cookies nicht wünschen,
              kann der jeweilige Browser der Nutzerin/des Nutzers so eingestellt werden,
              dass eine Verarbeitung von Cookies nicht akzeptiert wird. Hier ist allerdings zu beachten,
              dass die Website in diesem Fall ggf. nur eingeschränkt oder gar nicht genutzt werden kann.
              Wenn nur die Cookies der Website, nicht aber die Cookies der erwähnten Dienstleister
              und Partner akzeptiert werden sollen, kann die Einstellung „Cookies von Drittanbietern blockieren“
              im jeweiligen Browser gewählt werden.
            </p>
          </div>
        </div>
        <br>
        <div class="cell">
          <h4>4. Datensicherheit</h4>
          <p>
            4.1 Alle Informationen, die Website-Benutzerinnen und Benutzer an Der Träger übermitteln,
            werden auf Servern innerhalb Deutschlands gespeichert. Eine Datenverarbeitung ist insoweit
            außerhalb der EU bzw. des EWR ausgeschlossen.
          </p>
          <p>
            4.2 Es kann leider keine Gewähr dafür übernommen werden, dass die Übertragung von Informationen
            über das Internet vollständig sicher erfolgt, weshalb Der Träger die Sicherheit der
            über das Internet an die Website übermittelten Daten nicht garantieren kann.
            Der Träger sichert die Website und sonstige Systeme jedoch durch technische
            und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder
            Verbreitung der Daten der Nutzerinnen und Nutzer durch unbefugte Personen ab.
            Insbesondere werden die personenbezogenen Daten zur Schule verschlüsselt übertragen.
            Dafür wird das Codierungssystem TLS (Transport Layer Security) genutzt.
          </p>
          <p>
            4.3 Der Träger hat die Firma art soft and more GmbH (siehe oben) zum Betrieb des SoSE-Elterns
            im Rahmen der Auftragsverarbeitung nach Art. 28 DSGVO beauftragt.
            Die Firma stellt die dem SoSE-Eltern zugrundeliegende Software zur Verfügung
            und betreibt die dazu notwendigen Server und deren Anbindung zum Internet im Auftrag der Schule.
            Hierfür bedient sie sich des Rechenzentrums der Firma Hetzner Online GmbH als Nachunternehmerin.
          </p>
        </div>
        <br>
        <div class="cell">
          <h4>5. Keine Weitergabe Ihrer personenbezogenen Daten</h4>
          <p>
            5.1 Der Träger gibt personenbezogene Daten der Nutzerinnen und Nutzer nicht an Dritte weiter,
            es sei denn, es wurde in die Datenweitergabe eingewilligt oder Der Träger ist aufgrund
            gesetzlicher Bestimmungen und/oder behördlicher oder gerichtlicher Anordnungen zu einer
            Datenweitergabe berechtigt oder verpflichtet. Dabei kann es sich insbesondere um die
            Auskunftserteilung für Zwecke der Strafverfolgung, zur Gefahrenabwehr oder zur
            Durchsetzung geistiger Eigentumsrechte handeln.
          </p>
          <p>
            5.2 Eine Übertragung von einzelnen Daten (z.B. im Zusammenhang einer Krankmeldung
            oder eines Terminwunsches) aus dem SoSE-Eltern an das SoSE-Träger erfolgt nur dann,
            wenn im Einzelfall ein entsprechender Befehl in dem Funktionsangebot der Website ausgelöst wird.
            Insoweit wird darauf hingewiesen, dass konkrete Gesundheitsdaten oder Sozialdaten
            (siehe Art. 4 Ziffer 15 DSGVO sowie § 67 Abs.2 SGB X<sup>5</sup>)
            nicht über das SoSE-Eltern übermittelt werden sollten (also zum Beispiel lediglich
            die Krankmeldung als solche und nicht den Grund der Erkrankung).
          </p>
          <p>
            5.3 Bei der Übermittlung der entsprechenden Inhaltsdaten wird nur ein pseudonymisierter Schlüssel
            an das SoSE-Träger übertragen. Dieser kann nur von der Anwendung SoSE-Träger wieder aufgelöst
            (depseudonymisiert) werden. Innerhalb des SoSE-Trägers erfolgt die weitere Verarbeitung
            zu den gleichen strengen Datenschutzbedingungen ausschließlich für schulinterne Zwecke
            (insbesondere zur Weitergabe der Information an die dafür schulintern nach schulrechtlichen
            Vorschriften zuständige Stelle).
          </p>
        </div>
        <br>
        <div class="cell">
          <h4>6. Nutzung von anderen schulischen Datenbeständen / Schulverwaltungsprogramm</h4>
          <p>
            6.1 Im Rahmen der freiwilligen Nutzung der Kommunikationsdienste des SoSE-Elterns,
            werden die entsprechenden Daten (z.B. ein Terminwunsch für ein Elterngespräch)
            an das SoSE-Träger (= entsprechend gesichertes und vom SoSE-Eltern getrenntes
            Schulverwaltungsprogramm, auf welches nur Lehrkräfte und Mitarbeiterinnen und
            Mitarbeiter der Schule Zugriff haben) übertragen. Der Personenbezug dieser Informationen
            wird bei der Übertragung zusätzlich und gesondert durch Verwendung eines Pseudonyms
            geschützt. Die Übertragung muss in jedem Einzelfall von der jeweiligen Nutzerin/dem
            jeweiligen Nutzer persönlich ausgelöst werden.
          </p>
          <p>
            6.2 Soweit im SoSE-Eltern zur Anzeige von Informationen andere schulische Daten genutzt werden
            (z.B. Namen von Lehrkräften), werden diese nicht in den Datenbeständen des SoSE-Elterns gespeichert,
            sondern es erfolgt zur Laufzeit nur eine Anzeige dieser Daten. Die Datenbestände der
            beiden Anwendungen sind strikt voneinander getrennt.
          </p>
        </div>
        <br>
        <div class="cell">
          <h4>7. Datenschutz und Websites Dritter </h4>
          <p>
            Die Website kann Hyperlinks zu und von Websites Dritter enthalten.
            Wenn einem Hyperlink zu einer dieser Websites gefolgt wird, ist zu beachten,
            dass Der Träger keine Verantwortung oder Gewähr für fremde Inhalte oder Datenschutzbedingungen
            übernehmen kann. Bevor seitens der Nutzerinnen und Nutzer personenbezogene Daten an Websites
            Dritter übermittelt werden, ist sich über die dort jeweils geltenden Datenschutzbedingungen
            zu informieren.
          </p>
        </div>
        <br>
        <div class="cell">
          <h4>8. Einwilligung / Freiwilligkeit</h4>
          <p>
            8.1 Die Nutzung des Produkts SoSE-Eltern setzt eine vorher schriftlich gegenüber des Trägers / der
            Einrichtung
            zu erteilende eigenhändig unterschriebene Einwilligung voraus:
          </p>
          <p>
            8.1.1 Bei Erziehungsberechtigten von Kindern, die das 14. Lebensjahr noch nicht vollendet haben,
            genügt die Einwilligung der Erziehungsberechtigten, die sich sowohl auf die eigenen,
            wie auch die personenbezogenen Daten dieser Kinder beziehen muss;
          </p>
          <p>
            8.1.2 Bei Erziehungsberechtigten von Kindern, die das 14. Lebensjahr bereits erreicht haben,
            ist die Einwilligung der Erziehungsberechtigten für die eigenen personenbezogenen Daten,
            sowie zusätzlich eine Einwilligung dieser Kinder für deren eigenen personenbezogenen Daten notwendig.
          </p>
          <p>
            8.2 Soweit zum Zeitpunkt ab dem 14. bzw. 18. Geburtstag der jeweiligen Schülerinnen und Schüler
            die notwendigen Einwilligungen nicht bei der Schule vorliegen, wird der Zugang gesperrt.
            Wird die Einwilligung nach Aufforderung nicht unverzüglich erteilt,
            werden alle diesbezüglichen personenbezogenen Daten gemäß Ziffer 10 dieser Information gelöscht.
          </p>
          <p>
            8.3 <b>Die Einwilligung ist freiwillig</b> und kann jederzeit gegenüber der Schulleitung ohne nachteilige
            Folgen widerrufen werden. Wird die Einwilligung widerrufen, erfolgt die Löschung der Daten
            gemäß Ziffer 10 dieser Information.
          </p>
          <p>
            8.4 Aus der Nichtteilnahme an dem Dienst SoSE-Eltern entsteht kein Nachteil für die
            betroffenen Personen. In diesem Falle können die schulüblichen Kommunikationswege genutzt werden.
          </p>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <h4>9. Hinweis zur rechtlichen Wirkung bei der Nutzung der Kommunikationsdienste</h4>
            <p>
              9.1 Soweit Nutzerinnen und Nutzer des SoSE-Elterns über dessen Funktionen mit der Schule kommunizieren,
              dient das zunächst der schnelleren Information der Schule und damit der Verwaltungsvereinfachung.
              Soweit nach Rechtsvorschriften allerdings eine Erklärung in Schrift- oder Textform erwartet wird
              (z.B. bei einer Krankmeldung), muss diese in jedem Fall gesondert formgerecht nachgeholt werden.
            </p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <h4>10. Löschung von Daten / Regelfristen</h4>
            <p>
              10.1 Die persönlichen und nutzungsbezogenen Daten werden im SoSE-Eltern gelöscht,
              wenn die Betroffenen die erteilte Einwilligung widerrufen.
            </p>
            <p>
              10.2 Die Nutzungsdaten werden automatisch jeweils spätestens am Ende des laufenden Schuljahres gelöscht.
              Die persönlichen Daten im SoSE-Eltern werden jeweils spätestens am Ende des Schuljahres gelöscht,
              in dem die Schülerin oder der Schüler Der Träger verlässt (Schulwechsel oder Beendigung des Schulbesuchs).
            </p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <p>
              Im Rahmen des geltenden Datenschutzrechts besteht ein Recht auf Auskunft sowie ein Recht auf Berichtigung,
              Sperrung und Löschung der gespeicherten Daten der Nutzerinnen und Nutzer.
              Fragen bezüglich der Verarbeitung der personenbezogenen Daten durch Der Träger,
              sind an Der Träger unter den angegebenen Kontaktdaten zu richten.
            </p>
            <p>
              Der Träger behält sich das Recht vor, diese Datenschutzbestimmungen jederzeit mit Wirkung für die Zukunft
              zu ändern. Eine jeweils aktuelle Version ist auf der Website verfügbar. Die Website sollte hinsichtlich
              der Informationen über die jeweils geltenden Datenschutzbestimmungen aufgesucht werden.
            </p>
            <p>
              Im Übrigen wird auf die Homepage (<a href="https://www.bsi-fuer-buerger.de" target="blank">www.bsi-fuer-buerger.de</a>)
              des
              Bundesamtes für Sicherheit in der Informationstechnik (BSI) sowie die dortigen Informationen,
              praktischen Tipps und Empfehlungen verwiesen. Besonders folgende Empfehlungen des BSI sind zu beachten:
            </p>
            <ul type="disc">
              <li><a
                  href="https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/BasisschutzGeraet/BasisschutzGeraet_node.html"
                  target="blank">Basisschutz für Computer und Smartphone</a></li>
              <li><a
                  href="https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/Schutzprogramme/schutzprogramme_node.html"
                  target="blank">Schutzprogramme</a></li>
              <li><a href="https://www.bsi-fuer-buerger.de/BSIFB/DE/Empfehlungen/Passwoerter/passwoerter_node.html"
                     target="blank">Passwortvergabe</a></li>
            </ul>
            <p></p>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="cell">
            <hr>
            1 Bayerisches Datenschutzgesetz (BayDSG)<br>
            2 Datenschutzgrundverordnung (DSGVO)<br>
            4 Telemediengesetz (TMG)<br>
            5 Zehntes Buch Sozialgesetzbuch (SGB X)
          </div>
        </div>
        <br>


        <br>
        <div class="row">
          <div class="cell text-center">
            <button class="button primary" type="button" style="margin: 0;" @click="showDialog = false">
              Zur Kenntnis genommen
            </button>
          </div>
        </div>
      </md-dialog-content>

      <button class="close-button" type="button" @click="showDialog = false">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>
  </div>
</template>

<script>
  export default {
    name: 'PrivacyStatementView',

    data() {
      return {
        privacyStatement: true,
        showDialog: false,
      }
    },

    methods: {
      closeDialog() {
        this.showDialog = false;
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },

      labels() {
        return this.$store.getters.labels
      },

      isDevEnv() {
        return process.env.NODE_ENV === 'development';
      },
    }

  }
</script>

<style lang="scss" scoped>
  .md-dialog {
    height: 95%;
    max-height: 95%;
  }

  .md-theme-default a:not(.md-button) {
    color: dodgerblue;
  }

  p {
    margin: 0;
    text-align: justify;
    font-size: 95%;
    margin-bottom: 1rem;
  }

  i {
    color: seagreen;
  }

  h4 {
    font-size: 140%;
    font-weight: 500;
  }

  h5 {
    font-size: 115%;
    font-weight: 400;
  }

  span {
    color: cornflowerblue;
  }

  .privacy {
    text-transform: none;
    font-weight: 300;
    font-size: 1.2rem;
  }

  h4.privacy {
    margin: 0;
  }

  .recommend {
    cursor: pointer;
  }

  h4.product {
    transition: font-size 0.25s ease-in-out, color 0.25s ease-in-out;
    color: inherit;
    font-weight: 300;
    font-size: 1.1rem;
    margin-top: 11px;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }
</style>