import axios from 'axios';
import HttpErrorHandler from '../services/HttpErrorHandler';
import HttpUrlService from '../services/HttpUrlService';

export default {

  name: 'HttpInterceptor',

  httpClientInstance: null,
  mainVue: null,

  initInterceptors(mainVue) {

    this.mainVue = mainVue;

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {

      if (!(config.headers && config.headers['content-type'] === 'application/x-www-form-urlencoded')) {

        config.baseURL = HttpUrlService.getUrl('VUE_APP_SOSE_SERVER_API_URL');

        if (config.responseType && config.responseType === 'blob') {
          config.headers.common['Accept'] = 'image/png, image/jpg, application/json, application/pdf, application/octet-stream';
        } else if (!(config.headers && config.headers['accept'] === 'application/xml')) {
          config.headers.common['Content-Type'] = 'application/json';
          config.headers.common['Accept'] = 'application/json';
          config.headers.post['Content-Type'] = 'application/json';
          config.headers.post['Accept'] = 'application/json';
          config.headers.get['Content-Type'] = 'application/json';
          config.headers.get['Accept'] = 'application/json';
          config.headers.put['Content-Type'] = 'application/json';
          config.headers.put['Accept'] = 'application/json';
        }

        // Include current token before request is sent
        let token = sessionStorage.getItem('sose.access_token');
        config.headers.common['Authorization'] = 'Bearer ' + token;
        config.headers.post['Authorization'] = 'Bearer ' + token;
        config.headers.get['Authorization'] = 'Bearer ' + token;
        config.headers.put['Authorization'] = 'Bearer ' + token;
      }

      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
      // Do something with response data
      return response;
    }, error => {

      // Handle network error and request timeout
      if (error.message && (error.message.includes('Network Error') || error.message.includes('timeout'))) {
        // Exchange urls in config object and try again with next server
        let oldBaseUrl = HttpUrlService.setUrlUnavailable(error.config.url);
        let newServerUrl = HttpUrlService.getUrl('VUE_APP_SOSE_SERVER_API_URL');

        if (newServerUrl) {
          error.config.baseURL = newServerUrl;
          let newBaseUrl = HttpUrlService.getCurrentBaseUrl();
          error.config.url = error.config.url.replace(oldBaseUrl, newBaseUrl);
          return Promise.resolve(axios.request(error.config));
        }

        this.mainVue.$store.commit('errorMsg', 'SoSE Server nicht erreichbar.<br><b>Netzwerk Fehler</b>');
        return Promise.reject(error);
      }

      // Handle invalid token
      if ((error.response && error.response.data && error.response.data.error && error.response.data.error.includes('invalid_token')) ||
        // Handle expired token for binary service
        (error.response.status && error.response.status === 401 && error.response.config && error.response.config.url && error.response.config.url.includes('/binary'))) {

        if (error.response && error.response.data && error.response.data.error_description && error.response.data.error_description.includes('Invalid refresh token')) {
          this.forceLogout();
          return Promise.reject(error);
        }

        if (sessionStorage.getItem('sose.refresh_token')) {
          return this.refreshToken().then(response => {
            this.mainVue.$store.commit('session', response.data);
            error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access_token;
            return Promise.resolve(axios.request(error.response.config));
          }).catch(error => {
            console.log(JSON.stringify(error));
            if (!sessionStorage.getItem('sose.refresh_token')) {
              this.forceLogout();
            }
          });
        } else {
          this.forceLogout();
        }
      } else {
        return Promise.reject(error);
      }
    });
  },

  forceLogout() {
    this.mainVue.$store.commit('logout');
    HttpErrorHandler.handleError({response: {data: {error: 'invalid_token'}}}, this.mainVue, '');
  },

  httpClient() {
    if (sessionStorage.getItem('sose.reconnect')) {
      sessionStorage.removeItem('sose.reconnect');
    }

    axios.defaults.timeout = process.env.VUE_APP_SOSE_SERVER_RECONNECT_TIMEOUT_SECONDS * 1000;

    // Use central instance -> Interceptor -> Token added
    return axios;
  },

  httpClientWithConfig(config) {
    // Use central instance -> Interceptor -> Token added
    return axios(config);
  },

  doRefreshToken() {
    if (this.mainVue.$store.getters.webAppConfig.soseTokenRefreshDisabled) {
      return;
    }

    if (sessionStorage.getItem('sose.refresh_token')) {
      return this.refreshToken().then(response => {
        this.mainVue.$store.commit('session', response.data);
      }).catch(() => {
        this.forceLogout();
      });
    } else {
      this.forceLogout();
    }
  },

  refreshToken() {
    const data = {
      refreshToken: sessionStorage.getItem('sose.refresh_token')
    };

    let timeOut = process.env.VUE_APP_SOSE_SERVER_CONNECT_TIMEOUT_SECONDS * 1000;
    if (sessionStorage.getItem('sose.reconnect')) {
      sessionStorage.removeItem('sose.reconnect');
      timeOut = process.env.VUE_APP_SOSE_SERVER_RECONNECT_TIMEOUT_SECONDS * 1000;
    }

    const config = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
      },
      auth: {
        username: 'ai42sose',
        password: 'Da$wirdderHammer!'
      },
      data: data,
      url: HttpUrlService.getUrl('VUE_APP_SOSE_SERVER_OAUTH_URL'),
      timeout: timeOut,
    };
    return axios(config);
  },

  httpRecaptchaClient() {
    // Create new instance -> No Interceptor -> No Token
    return axios.create({
      baseURL: process.env.VUE_APP_SOSE_SERVER_API_URL,
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
        'x-recaptcha': localStorage.getItem('x-recaptcha'),
      },
    });
  },

  httpNoAuthClient() {
    // Create new instance -> No Interceptor -> No Token
    let axiosNoAuthClient = axios.create({
      baseURL: process.env.VUE_APP_SOSE_SERVER_API_URL,
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
      },
    });

    axiosNoAuthClient.defaults.timeout = process.env.VUE_APP_SOSE_SERVER_RECONNECT_TIMEOUT_SECONDS * 1000;

    return axiosNoAuthClient;
  },

  httpNoAuthBinaryClient() {
    // Create new instance -> No Interceptor -> No Token
    return axios.create({
      baseURL: process.env.VUE_APP_SOSE_SERVER_API_URL,
      headers: {
        'content-type': 'application/json',
        'accept': 'image/png, image/jpg, application/json, application/pdf, application/octet-stream',
      },
    });
  },
}
