import Vue from 'vue';
import Vuex from 'vuex';
import VueMoment from 'vue-moment';
import {Md5} from 'ts-md5';
import HttpUrlService from './services/HttpUrlService';
import Organization from "./entities/Organization";
import Contract from "@/entities/Contract";

Vue.use(Vuex);

const moment = require('moment');
Vue.use(VueMoment, {
  moment
});
Vue.moment.locale('de');

function sortObject(obj) {
  let sorted = [];
  Object.values(obj).sort().forEach(value => {
    Object.entries(obj).forEach(entry => {
      if (entry[1] === value) {
        sorted.push(entry[0]);
      }
    })
  });
  return sorted;
}

export default new Vuex.Store({

  state: {
    // Authenticated
    authenticated: false,

    // User object
    user: {roles: ['role.visitor']},

    // Active user roles
    admin: false,
    sage: false,
    pseudoSage: false,
    treasurer: false,
    hrmanager: false,
    facility: false,
    facilityAdmin: false,
    parent: false,
    documentation: false,
    management: false,
    beta: false,
    canWrite: false,
    deleted: false,

    // App version
    version: {server: {version: null}, frontend: { version: '20240803-1-239' }},

    // Organization object
    organization: Organization.ai42Organization,

    // Organization contract object
    organizationContract: Contract.contract,

    // Router history
    routingHistory: [],
    routingBlackList: ['Logout', 'Login', 'Registrierung', 'Preise', 'Passwort', 'Konto/Aktivierung', 'Kuendigung'],
    challengeLoginRoute: '',
    navigationRoutes: [],

    // Data from Info service
    labels: [],
    modules: [],

    // Data from User service
    availableProfiles: [],

    // Data from Organization service
    availableFacilities: [],
    allowedFacilities: [],

    // Data from Employee service
    availableOrganizationEmployees: [],

    // Internal app states
    dataImportFacility: null,
    selectedFacility: null,

    // Messages
    errorMsg: '',
    prevErrorMsg: '',
    errorMsgTime: Vue.moment(),
    successMsg: '',
    notificationMsg: '',
    announcementMsg: '',
    versionMsg: '',
    warningMsg: '',
    prevWarningMsg: '',
    warningMsgTime: Vue.moment(),

    // Printing
    printTableList: [],
    printTableFilters: {},
    printTableType: '',
    printMode: false,

    // Dark mode
    darkMode: false,

    // Session
    session: {
      start: null,
      access_token: null,
      accessTokenExpiresIn: 101,
      refresh_token: null,
      refreshTokenExpiresIn: null,
    },

    // ???
    webAppSettings: {
      pwdSecureLength: 8,
    },

    // Web app config read from CMS
    webAppConfigDefault: {
      soseLoginDisabled: false,
      soseLoginMagic: '',
      soseRegistrationDisabled: false,
      soseContractCancellationDisabled: false,
      soseTokenRefreshDisabled: false,
      soseAnnouncementDisabled: true,
      soseRecommendationDisabled: false,
      soseStatisticDisabled : false,
      soseWebAppVersion : '',
    },
    webAppConfig: null,

    // Server list for load balancing
    serverList: [],
    serverListIndex: -1,

    // Data from Facility service
    closingTimes: [],
    disabledClosedDays: [],

    // Static filter values
    filters: [
      {name: 'ehemalige Kinder', id: 'PAST'},
      {name: 'aktuelle Kinder', id: 'CURRENT'},
      {name: 'zukünftige Kinder', id: 'FUTURE'},
      {name: 'Austritte laufendes Jahr', id: 'FUTURE_EXIT_CURRENT'},
      {name: 'Austritte alle zukünftigen', id: 'FUTURE_EXIT'},
    ],
  },

  mutations: {

    routingHistory(state, from) {
      if (state.routingHistory.length > 9) {
        state.routingHistory = state.routingHistory.slice(1);
      }
      state.routingHistory.push(from);
    },

    routingBlackList(state, blackList) {
      state.routingBlackList = blackList;
    },

    challengeLoginRoute(state, route) {
      state.challengeLoginRoute = route;
    },

    navigationRoutes(state, routes) {
      state.navigationRoutes = routes;
    },

    dataImportFacility(state, facility) {
      state.dataImportFacility = facility;
    },

    printTableFilters(state, filters) {
      state.printTableFilters = filters;
    },

    printTableList(state, list) {
      state.printTableList = list;
    },

    printTableType(state, list) {
      state.printTableType = list;
    },

    printMode(state, mode) {
      state.printMode = mode;
    },

    darkMode(state, mode) {
      state.darkMode = mode;
    },

    errorMsg(state, msg) {
      setTimeout(() => {
        if (msg.length > 0) {
          if ((msg !== state.prevErrorMsg) ||
            (msg === state.prevErrorMsg && Vue.moment().isAfter(Vue.moment(state.errorMsgTime).add(1, 's')))) {
            state.prevErrorMsg = msg;
            state.errorMsg = '<i>' + Vue.moment().format('LL  LTS') + '</i><br>' + msg;
            state.errorMsgTime = Vue.moment();
          }
        } else {
          state.errorMsg = msg;
        }
      }, 500);
    },

    successMsg(state, msg) {
      setTimeout(() => {
        if (msg.length > 0) {
          state.successMsg = '<i>' + Vue.moment().format('LL  LTS') + '</i><br>' + msg;
        } else {
          state.successMsg = msg;
        }
      }, 500);
    },

    notificationMsg(state, msg) {
      setTimeout(() => {
        if (msg.length > 0) {
          state.notificationMsg = '<i>' + Vue.moment().format('LL  LTS') + '</i><br>' + msg;
        } else {
          state.notificationMsg = msg;
        }
      }, 500);
    },

    warningMsg(state, msg) {
      setTimeout(() => {
        if (msg.length > 0) {
          if ((msg !== state.prevWarningMsg) ||
            (msg === state.prevWarningMsg && Vue.moment().isAfter(Vue.moment(state.warningMsgTime).add(30, 's')))) {
            state.prevWarningMsg = msg;
            state.warningMsg = '<i>' + Vue.moment().format('LL  LTS') + '</i><br>' + msg;
            state.warningMsgTime = Vue.moment();
          }
        } else {
          state.warningMsg = msg;
        }
      }, 500);
    },

    announcementMsg(state, msg) {
      setTimeout(() => {
        state.announcementMsg = msg;
        sessionStorage.setItem('sose.announcementMsg', state.announcementMsg);
      }, 500);
    },

    versionMsg(state, msg) {
      setTimeout(() => {
        state.versionMsg = msg;
      }, 500);
    },

    webAppConfig(state, data) {
      if (data) {
        let configStart = data.indexOf('{');
        let configEnd = data.lastIndexOf('}') + 1;
        let config = data.substring(configStart, configEnd).trim();
        config = config.replace(/[\s\n\r]/g, '');

        // Handle common mistake of forbidden last colon in JSON structure
        if (config.substr(config.lastIndexOf('}') - 1, 1) === ',') {
          config = config.substr(0, config.lastIndexOf(',')) + '}';
        }

        let webAppConfig = JSON.parse(config);

        state.webAppConfig = {
          soseLoginDisabled: webAppConfig.soseLoginDisabled === 'true',
          soseLoginMagic: webAppConfig.soseLoginMagic,
          soseRegistrationDisabled: webAppConfig.soseRegistrationDisabled === 'true',
          soseContractCancellationDisabled: webAppConfig.soseContractCancellationDisabled === 'true',
          soseTokenRefreshDisabled: webAppConfig.soseTokenRefreshDisabled === 'true',
          soseAnnouncementDisabled: webAppConfig.soseAnnouncementDisabled === 'true',
          soseRecommendationDisabled: webAppConfig.soseRecommendationDisabled === 'true',
          soseStatisticDisabled: webAppConfig.soseStatisticDisabled === 'true',
          soseWebAppVersion : webAppConfig.soseWebAppVersion,
        };

      } else {
        state.webAppConfig = state.webAppConfigDefault;
      }
    },

    serverList(state, list) {
      state.serverList = list;
    },

    serverListIndex(state, index) {
      state.serverListIndex = index;
    },

    session(state, data) {
      let session = {
        start: null,
        access_token: null,
        accessTokenExpiresIn: 101,
        refresh_token: null,
        refreshTokenExpiresIn: null,
      };

      session.start = Vue.moment();
      let start = localStorage.getItem('sose.session_start');
      if (start) {
        session.start = Vue.moment(start, 'L LTS');
      }

      if (data) {
        session.access_token = data.access_token;
        session.accessTokenExpiresIn = data.expires_in;
        // session.accessTokenExpiresIn = 50;
        session.refreshTokenExpiresIn = data.refresh_expires_in;
        // session.refreshTokenExpiresIn = 60;
        session.refresh_token = data.refresh_token;

        sessionStorage.setItem('sose.access_token_expires_in', session.accessTokenExpiresIn);
        sessionStorage.setItem('sose.access_token', session.access_token);
        sessionStorage.setItem('sose.refresh_token', session.refresh_token);

        session.start = Vue.moment();

      } else {
        let access_token = sessionStorage.getItem('sose.access_token');
        if (access_token) {
          session.access_token = access_token;
        }

        let accessTokenExpiresIn = sessionStorage.getItem('sose.access_token_expires_in');
        if (accessTokenExpiresIn) {
          session.accessTokenExpiresIn = Number(accessTokenExpiresIn);
        }

        let refresh_token = sessionStorage.getItem('sose.refresh_token');
        if (refresh_token) {
          session.access_token = refresh_token;
        }

        let refreshTokenExpiresIn = sessionStorage.getItem('sose.refresh_token_expires_in');
        if (refreshTokenExpiresIn) {
          session.refreshTokenExpiresIn = Number(refreshTokenExpiresIn);
        }
      }

      localStorage.setItem('sose.session_start', Vue.moment(session.start).format('L LTS'));

      state.session = session;
    },

    logout(state) {
      state.authenticated = false;
      state.admin = false;
      state.sage = false;
      state.treasurer = false;
      state.hrmanager = false;
      state.facility = false;
      state.facilityAdmin = false;
      state.parent = false;
      state.documentation = false;
      state.management = false;
      state.beta = false;
      state.canWrite = false;
      state.deleted = false;
      state.user = {roles: ['role.visitor']};
      state.organization = {name: 'ai42', responsiblePerson: {salutation: 'Sage', firstname: 'AI', lastname: '42'}};
      state.organizationContract = Contract.contract,
      state.announcementMsg = '';

      sessionStorage.removeItem('sose.access_token');
      sessionStorage.removeItem('sose.access_token_expires_in');
      sessionStorage.removeItem('sose.refresh_token');
      sessionStorage.removeItem('sose.refresh_token_expires_in');
      sessionStorage.removeItem('sose.announcementMsg');
      sessionStorage.removeItem('sose.announcementMsg.showAgain');
      sessionStorage.removeItem('sose.versionMsg.alreadyShown');
      sessionStorage.removeItem('sose.licenseViolationReminder');
    },

    userInfo(state, user) {
      state.user = user;
      if (!state.user.allowedFacilities) {
        state.user.allowedFacilities = [];
      }
      state.user.md5 = Md5.hashAsciiStr(state.user.id);

      state.sage = false;
      state.pseudoSage = false;
      state.canWrite = false;
      state.treasurer = false;
      state.hrmanager = false;
      state.facility = false;
      state.parent = false;
      state.documentation = false;
      state.beta = user.beta;

      state.management = user.manager;
      state.admin = user.admin;
      state.facilityAdmin = user.facilityAdmin;
      state.canWrite = state.admin || state.facilityAdmin;

      for (let role of user.roles) {
        if ((role.indexOf('sage') >= 0) && (state.user.customerNo.indexOf('ZZ-') === 0)) {
          state.sage = true;
          state.canWrite = true;
        }
        if ((role.indexOf('sage') >= 0) && (state.user.customerNo.indexOf('ZZ-') === -1)) {
          state.pseudoSage = true;
        }
        if (role.indexOf('treasurer') >= 0) {
          state.treasurer = true;
        }
        if (role.indexOf('hrmanager') >= 0) {
          state.hrmanager = true;
        }
        if (role.indexOf('facility') >= 0) {
          state.facility = true;
        }
        if (role.indexOf('parent') >= 0) {
          state.parent = true;
        }
        if (role.indexOf('documentation') >= 0) {
          state.documentation = !state.admin;
        }
        if (role.indexOf('deleted') >= 0) {
          state.deleted = true;
        }
        if (role.indexOf('beta') >= 0) {
          state.beta = true;
        }
        if (role.indexOf('write') >= 0) {
          state.canWrite = true;
        }
      }

      if ((state.pseudoSage) && (user.roles.indexOf('role.sage') >= 0)) {
        user.roles.splice(user.roles.indexOf('role.sage'), 1);
      }

      if ((!state.sage || state.pseudoSage) && (state.labels.availableProfiles.indexOf('sage') >= 0)) {
        state.labels.availableProfiles.splice(state.labels.availableProfiles.indexOf('sage'), 1);
      }

      state.authenticated = true;
    },

    organizationInfo(state, organization) {
      state.organization = organization;
    },

    organizationContract(state, organizationContract) {
      state.organizationContract = organizationContract;
    },

    versionInfo(state, data) {
      state.version = {server: {version: data.version}, frontend: { version: state.version.frontend.version }};
      sessionStorage.setItem('sose.refresh_token_expires_in', state.session.refreshTokenExpiresIn);
    },

    labelsLightInfo(state, labels) {
      state.labels = labels;

      let countryMap = new Map();
      for (let country of Object.entries(labels.country)) {
        countryMap.set(country[1], country[0]);
      }
      countryMap = new Map([...countryMap.entries()].sort());
      let sortedCountries = {};
      countryMap.forEach((key, value) => {
        sortedCountries[key] = value;
      });
      labels.country = JSON.parse(JSON.stringify(sortedCountries));

      labels.availableCountryEntries = [];
      Object.entries(labels.country).forEach(entry => labels.availableCountryEntries.push({
        id: entry[0],
        name: entry[1]
      }));

      labels.organizationTypes = sortObject(labels.organizationType);

      labels.paymentMethods = [];
      Object.keys(labels.paymentMethod).forEach(key => labels.paymentMethods.push(key));

      labels.paymentIntervals = [];
      Object.keys(labels.paymentInterval).forEach(key => labels.paymentIntervals.push(key));

      labels.moduleTypes = [];
      Object.keys(labels.moduleType).forEach(key => labels.moduleTypes.push(key));

      labels.errorMessages = [];
      Object.keys(labels.errorMessage).forEach(key => labels.errorMessages.push(key));

      state.labels = labels;
    },

    labelsInfo(state, labels) {
      state.labels = labels;

      let countryMap = new Map();
      for (let country of Object.entries(labels.country)) {
        countryMap.set(country[1], country[0]);
      }
      countryMap = new Map([...countryMap.entries()].sort());
      let sortedCountries = {};
      countryMap.forEach((key, value) => {
        sortedCountries[key] = value;
      });
      labels.country = JSON.parse(JSON.stringify(sortedCountries));

      labels.availableCountryEntries = [];
      Object.entries(labels.country).forEach(entry => labels.availableCountryEntries.push({
        id: entry[0],
        name: entry[1]
      }));

      labels.organizationTypes = sortObject(labels.organizationType);

      labels.paymentMethods = [];
      Object.keys(labels.paymentMethod).forEach(key => labels.paymentMethods.push(key));

      labels.paymentIntervals = [];
      Object.keys(labels.paymentInterval).forEach(key => labels.paymentIntervals.push(key));

      labels.moduleTypes = [];
      Object.keys(labels.moduleType).forEach(key => labels.moduleTypes.push(key));

      labels.errorMessages = [];
      Object.keys(labels.errorMessage).forEach(key => labels.errorMessages.push(key));

      labels.absenceStates = [];
      Object.keys(labels.absenceStatus).forEach(key => labels.absenceStates.push(key));

      let languageMap = new Map();
      for (let language of Object.entries(labels.language)) {
        languageMap.set(language[1], language[0]);
      }
      languageMap = new Map([...languageMap.entries()].sort());
      let sortedLanguages = {};
      languageMap.forEach((key, value) => {
        sortedLanguages[key] = value;
      });
      labels.language = JSON.parse(JSON.stringify(sortedLanguages));

      labels.availableLanguageEntries = [];
      Object.entries(labels.language).forEach(entry => labels.availableLanguageEntries.push({
        id: entry[0],
        name: entry[1]
      }));

      labels.divisionTypes = [];
      Object.keys(labels.divisiontype).forEach(key => labels.divisionTypes.push(key));

      labels.childRelationTypes = sortObject(labels.childRelation);

      labels.alertingTypes = [];
      Object.keys(labels.alertingType).forEach(key => labels.alertingTypes.push(key));

      labels.facilityTypes = sortObject(labels.facilityType);

      labels.kibigtimeslots = [];
      Object.keys(labels.kibigtimeslot).forEach(key => labels.kibigtimeslots.push(key));

      labels.absenceTypes = [];
      Object.keys(labels.absenceType).forEach(key => labels.absenceTypes.push(key));

      labels.accountingStates = [];
      Object.keys(labels.accountingState).forEach(key => labels.accountingStates.push(key));

      labels.billingStates = sortObject(labels.billingState);

      labels.feeTypes = sortObject(labels.feeType);

      labels.jobPositions = sortObject(labels.jobPosition);

      labels.professionalQualifications = sortObject(labels.professionalQualification);

      labels.qualifications = sortObject(labels.qualification);

      labels.workingAreas = sortObject(labels.workingArea);

      labels.employeeTypes = sortObject(labels.employeeType);

      labels.positions = sortObject(labels.position);

      labels.salutations = [];
      Object.keys(labels.salutation).forEach(key => labels.salutations.push(key));

      labels.sexs = [];
      Object.keys(labels.sex).forEach(key => labels.sexs.push(key));

      labels.alertingTypes = [];
      Object.keys(labels.alertingType).forEach(key => labels.alertingTypes.push(key));

      labels.mandateTypes = [];
      Object.keys(labels.mandateType).forEach(key => labels.mandateTypes.push(key));

      labels.feedbackStatuss = sortObject(labels.feedbackStatus);

      labels.feedbackPriorities = [];
      Object.keys(labels.feedbackPriority).forEach(key => labels.feedbackPriorities.push(key));

      labels.feedbackTypes = sortObject(labels.feedbackType);

      labels.bookingTimeTypes = [];
      Object.keys(labels.bookingTimeType).forEach(key => labels.bookingTimeTypes.push(key));

      labels.kibigImportModes = [];
      Object.keys(labels.kibigImportMode).forEach(key => labels.kibigImportModes.push(key));

      labels.priceListTypes = [];
      Object.keys(labels.priceListType).forEach(key => labels.priceListTypes.push(key));

      labels.handicapTypes = [];
      Object.keys(labels.handicapType).forEach(key => labels.handicapTypes.push(key));

      labels.templateTypes = sortObject(labels.templateType);

      labels.supportFactors = [];
      Object.keys(labels.supportFactor).forEach(key => labels.supportFactors.push(key));

      labels.klassenstufen = [];
      Object.keys(labels.klassenstufe).forEach(key => labels.klassenstufen.push(key));

      labels.fontTypes = sortObject(labels.fontType);

      labels.availableProfiles = sortObject(labels.rolesprofile);

      // ToDo: Remove this block when parent module is available
      if (!state.beta) {
        if (labels.availableProfiles.indexOf('parent') >= 0) {
          labels.availableProfiles.splice(labels.availableProfiles.indexOf('parent'), 1);
        }
      }

      labels.reminderLevels = [];
      Object.keys(labels.reminderLevel).forEach(key => labels.reminderLevels.push(key));

      labels.organizationAttribute = {
        PAYING_CUSTOMER: "Kunde",
        PARTNER: "Partner",
        HAS_FACILITIES: "Einrichtungen angelegt",
        TEST_PHASE_ACTIVE: "Test-Phase aktiv",
        TEST_PHASE_EXPIRED: "Test-Phase beendet"
      };
      labels.organizationAttributes = [];
      Object.keys(labels.organizationAttribute).forEach(key => labels.organizationAttributes.push(key));

      labels.freeAttributeType = {
        BOOLEAN: "Checkbox",
        STRING: "Text",
        DOUBLE: "Zahl",
      };
      labels.freeAttributeTypes = [];
      Object.keys(labels.freeAttributeType).forEach(key => labels.freeAttributeTypes.push(key));

      labels.backupInterval = {
        DAILY: "Täglich",
        WEEKLY: "Wöchentlich",
        MONTHLY: "Monatlich",
      };
      labels.backupIntervals = [];
      Object.keys(labels.backupInterval).forEach(key => labels.backupIntervals.push(key));

      labels.bezirkFachkraftstundenType = {
        NO: "Keine Fachkraftstunden",
        TEN: "10 Fachkraftstunden",
        FIFTY: "50 Fachkraftstunden",
      };
      labels.bezirkFachkraftstundenTypes = [];
      Object.keys(labels.bezirkFachkraftstundenType).forEach(key => labels.bezirkFachkraftstundenTypes.push(key));

      labels.schoolHolidaysNames = [];
      Object.keys(labels.schoolHolidaysName).forEach(key => labels.schoolHolidaysNames.push(key));

      state.labels = labels;
    },

    modules(state, modules) {
      state.modules = modules;
    },

    availableProfiles(state, profiles) {
      let rolesProfile = {};
      profiles.forEach(profile => rolesProfile[profile] = state.labels.rolesprofile[profile]);
      state.availableProfiles = sortObject(rolesProfile);
      if ((!state.sage || state.pseudoSage) && state.availableProfiles.indexOf('sage') >= 0) {
        state.availableProfiles.splice(state.availableProfiles.indexOf('sage'), 1);
      }

      // Remove parent roles always since these were managed by the service
      // parents/{user-id}/login/update
      if (state.availableProfiles.indexOf('parent-read') >= 0) {
        state.availableProfiles.splice(state.availableProfiles.indexOf('parent-read'), 1);
      }
      if (state.availableProfiles.indexOf('parent-readwrite') >= 0) {
        state.availableProfiles.splice(state.availableProfiles.indexOf('parent-readwrite'), 1);
      }
    },

    availableFacilities(state) {
      state.availableFacilities = [];
      if (state.organization.facilities) {
        let allFacilities = false;
        state.user.allowedFacilities.forEach(allowedFacility => {
          if (allowedFacility === '*') {
            allFacilities = true;
          }
        })

        if (state.admin || allFacilities) {
          state.availableFacilities.push({name: 'Alle', longName: 'Alle', referenceNumber: '*', id: 'c35b3ee263c45ef263c4c35b3e263c45ef9d911a3ee0'});
          state.organization.facilities.forEach(facility => state.availableFacilities.push(facility));
        } else {
          for (let facility of state.organization.facilities) {
            for (let allowedFacility of state.user.allowedFacilities) {
              if (allowedFacility === facility.referenceNumber) {
                state.availableFacilities.push(facility);
              }
            }
          }
        }

        state.allowedFacilities = [];
        for (let facility of state.availableFacilities) {
          if (facility.referenceNumber !== '*') {
            state.allowedFacilities.push(facility);
          }
        }

        state.allowedFacilities.forEach(facility => {
          facility.longName = facility.name;
          if (facility.abbreviation) {
            facility.longName += ' (' + facility.abbreviation + ')';
          }
        })
      }
    },

    selectedFacilityReferenceNumber(state, referenceNumber) {
      this.commit('selectedFacility', null);
      state.availableFacilities.forEach(facility => {
        if (facility.referenceNumber === referenceNumber && referenceNumber !== '*') {
          this.commit('selectedFacility', facility);
        }
      })
    },

    selectedFacility(state, facility) {
      state.selectedFacility = facility;
    },

    availableOrganizationEmployees(state, employees) {
      state.availableOrganizationEmployees = employees;
    },

    closingTimes(state, closingTimes) {
      state.closingTimes = closingTimes;
      state.disabledClosedDays = [];
      for (let ct of state.closingTimes) {

        let from = Vue.moment(ct.from, 'YYYY-MM-DD');
        let until = Vue.moment(ct.until, 'YYYY-MM-DD');

        while (Vue.moment(from).isSameOrBefore(Vue.moment(until))) {
          state.disabledClosedDays.push(Vue.moment(from).toDate());
          from = Vue.moment(from).add(1 , 'd');
        }
      }
    },
  },

  getters: {
    webAppSettings: state => {
      return state.webAppSettings;
    },

    webAppConfig: state => {
      if (state.webAppConfig) {
        return state.webAppConfig;
      } else {
        return state.webAppConfigDefault;
      }
    },

    serverList: state => {
      return state.serverList;
    },

    serverListIndex: state => {
      return state.serverListIndex;
    },

    session: state => {
      return state.session;
    },

    dataImportFacility: state => {
      return state.dataImportFacility;
    },

    printTableFilters: state => {
      return state.printTableFilters;
    },

    printTableList: state => {
      return state.printTableList;
    },

    printTableType: state => {
      return state.printTableType;
    },

    printMode: state => {
      return state.printMode;
    },

    darkMode: state => {
      return state.darkMode;
    },

    errorMsg: state => {
      return state.errorMsg;
    },

    successMsg: state => {
      return state.successMsg;
    },

    notificationMsg: state => {
      return state.notificationMsg;
    },

    announcementMsg: state => {
      return state.announcementMsg;
    },

    versionMsg: state => {
      return state.versionMsg;
    },

    warningMsg: state => {
      return state.warningMsg;
    },

    serverUrl: () => {
      return HttpUrlService.getUrl('VUE_APP_SOSE_SERVER_API_URL');
    },

    authenticated: state => {
      return state.authenticated;
    },

    user: state => {
      return state.user;
    },

    organization: state => {
      return state.organization;
    },

    organizationContract: state => {
      return state.organizationContract;
    },

    admin: state => {
      return state.admin;
    },

    facilityAdmin: state => {
      return state.facilityAdmin;
    },

    sage: state => {
      return state.sage;
    },

    pseudoSage: state => {
      return state.pseudoSage;
    },

    treasurer: state => {
      return state.treasurer;
    },

    hrmanager: state => {
      return state.hrmanager;
    },

    facility: state => {
      return state.facility;
    },

    parent: state => {
      return state.parent;
    },

    documentation: state => {
      return state.documentation;
    },

    management: state => {
      return state.management;
    },

    beta: state => {
      return state.beta;
    },

    deleted: state => {
      return state.deleted;
    },

    canWrite: state => {
      return state.canWrite;
    },

    version: state => {
      return state.version;
    },

    labels: state => {
      return state.labels;
    },

    filters: state => {
      return state.filters;
    },

    modules: state => {
      return state.modules;
    },

    routingHistory: state => {
      return state.routingHistory;
    },

    challengeLoginRoute: state => {
      return state.challengeLoginRoute;
    },

    navigationRoutes: state => {
      return state.navigationRoutes;
    },

    routingBlackList: state => {
      return state.routingBlackList;
    },

    availableProfiles: state => {
      return state.availableProfiles;
    },

    availableFacilities: state => {
      return state.availableFacilities;
    },

    selectedFacilityReferenceNumber: state => {
      return state.selectedFacility.referenceNumber;
    },

    selectedFacilityName: state => {
      return state.selectedFacility.name;
    },

    selectedFacility: state => {
      return state.selectedFacility;
    },

    allowedFacilities: state => {
      return state.allowedFacilities;
    },

    availableOrganizationEmployees: state => {
      return state.availableOrganizationEmployees;
    },

    closingTimes: state => {
      return state.closingTimes;
    },

    disabledClosedDays: state => {
      return state.disabledClosedDays;
    },
  },
  actions: {}
})
