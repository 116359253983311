<template>
  <div id="app">
    <div class="content">
      <header id="header" :class="getHeaderClass()" :hidden="fullScreen()">
        <div v-if="(organization.name || user.email) && isAuthenticated" class="grid-x grid-padding-x grid-padding-y">
          <div v-if="!isSage && !isParent" class="cell large-8 medium-8 text-left">
            <div class="grid-x">
              <div class="cell small-7">
                <h4>{{ organization.name }}</h4>
                <h6>{{ organization.customerNo }}
                  <i v-if="organization.timejumpActive" class="flash-purple">
                    {{ organization.timejumpTime | moment('DD.MM.YYYY LTS') }}
                    <md-tooltip>Zeitsprung aktiv</md-tooltip>
                  </i>
                </h6>
              </div>
              <div class="cell small-4">
                <h5 v-if="organization.testPhaseActive" style="margin: 0;">
                  <router-link v-if="!isOnRegistrationView()" to="/Registrierung">
                    <md-button style="margin: 0; position: relative; top: -4px; left: -8px;">
                      <span class="test-phase">&nbsp;TEST-LIZENZ&nbsp;&nbsp;&nbsp;&nbsp;
                        <b v-if="!organization.testPhaseExpired">bis {{
                            organization.testPhaseUntil | moment("dddd D MMMM YYYY")
                          }}</b>
                        <b v-else style="color: crimson">ABGELAUFEN!</b>
                      </span>
                      <md-tooltip><span v-if="organization.testPhaseActive"><b>Zufrieden?</b> SoSE Bundle auswählen und jetzt bestellen!</span>
                      </md-tooltip>
                    </md-button>
                  </router-link>
                  <span v-else class="test-phase">
                    &nbsp;TEST-LIZENZ&nbsp;&nbsp;&nbsp;&nbsp;
                    <b v-if="!organization.testPhaseExpired">bis {{
                        organization.testPhaseUntil | moment("dddd D MMMM YYYY")
                      }}</b>
                    <b v-else style="color: crimson">ABGELAUFEN!</b>
                  </span>
                </h5>
              </div>
              <div class="cell small-1">
                <md-button v-if="organization.timejumpActive" @click="onDeactivateTimeJumpBackup"
                           class="md-icon-button md-raised md-forth flash-purple">
                  <md-icon>restore</md-icon>
                  <md-tooltip>Zeitsprung deaktivieren</md-tooltip>
                </md-button>
              </div>
            </div>
          </div>
          <div v-else-if="isSage" class="cell large-8 medium-8 text-left"><h4 style="color: greenyellow;">ai42 sage</h4>
          </div>
          <div v-else-if="isParent" class="cell large-8 medium-8 text-left"><h4>Elternportal</h4></div>
          <div class="cell small-1">
            <md-switch v-model="darkModeState" class="md-primary" @change="toggleDarkMode">
              <md-tooltip>Wechsel Normal- und Dunkelmodus</md-tooltip>
              Dunkel
            </md-switch>
          </div>
          <div class="cell large-3 medium-3 small-11 text-right">
            <router-link v-if="isPseudoSage" key="111" to="/Mein-Konto">
              <h6 class="my-account flash-purple">
                <md-icon>fingerprint</md-icon>
                {{ user.email }}
              </h6>
              <md-tooltip md-direction="bottom" style="font-size: medium;min-height: 3rem;">
                Mein Konto bearbeiten<br>
                <b>Hier kann die Identität wieder abgelegt werden.</b>
              </md-tooltip>
            </router-link>
            <router-link v-else key="111" to="/Mein-Konto">
              <h6 class="my-account">{{ user.email }}</h6>
              <md-tooltip md-direction="bottom" style="font-size: medium;">Mein Konto bearbeiten</md-tooltip>
            </router-link>
          </div>
        </div>
        <div v-else style="height: 1px">&nbsp;</div>
      </header>
      <div id="navigation" data-sticky-container :hidden="fullScreen()">
        <div data-sticky data-margin-top="0" data-top-anchor="header:bottom" :class="getNavClass()">
          <Navigation :currentPath="currentPath()"></Navigation>
        </div>
      </div>
      <main style="height: auto;">
        <div
            v-if="organization && organization.testPhaseActive && organization.testPhaseExpired && !isOnRegistrationView() && !isOnAccountActivationView()"
            class="grid-x grid-padding-x grid-padding-y">
          <div class="cell large-4 medium-3 hide-for-small-only"></div>
          <div class="cell large-4 medium-6 small-12">
            <TestPhaseOverCard style="max-width: 450px" class="text-center"/>
          </div>
          <div class="cell large-4 medium-3 hide-for-small-only"></div>
        </div>
        <router-view v-else/>
        <snackbar :notification="notification" :notificationMsg="notificationMsg"
                  :announcement="announcement" :announcementMsg="announcementMsg"
                  :version="version" :versionMsg="versionMsg"
                  :success="success" :successMsg="successMsg"
                  :warning="warning" :warningMsg="warningMsg"
                  :error="error" :errorMsg="errorMsg"/>
      </main>
    </div>
    <footer v-if="!fullScreen()" id="footer" :class="getFooterClass()">
      <div class="grid-x grid-padding-x grid-padding-y">
        <div class="cell large-3 medium-3 text-left hide-for-small-only">
          <RecommendDialog v-if="!isParent"
                           :isVisible="$router.currentRoute.fullPath.toLowerCase() !== '/login'"></RecommendDialog>
        </div>
        <div class="cell large-6 medium-6 small-12 text-center">
          <router-link v-if="!isOnRegistrationView() && (!isAuthenticated || organization.testPhaseActive)"
                       to="/Registrierung">
            <md-button class="register"><h4 class="register">Registrierung</h4></md-button>
            <md-tooltip>
              <span
                  v-if="organization.testPhaseActive"><b>Zufrieden?</b> SoSE Bundle auswählen und jetzt bestellen!</span>
              <span v-else><b>Test-Lizenz</b> anfordern oder <b>SoSE Bundle</b> auswählen und bestellen!</span>
            </md-tooltip>
          </router-link>
          <a href="https://ai42.de/unternehmen/agb" target="_blank">
            <md-button class="register"><h4 class="register">AGB</h4></md-button>
            <md-tooltip>
              <span>Allgemeine Geschäftsbedingungen</span>
            </md-tooltip>
          </a>
          <a href="https://ai42.de/unternehmen/datenschutz" target="_blank">
            <md-button class="register"><h4 class="register">Datenschutz</h4></md-button>
            <md-tooltip>
              <span>Datenschutzerklärung</span>
            </md-tooltip>
          </a>
        </div>
        <div class="cell large-3 medium-3 hide-for-small-only text-right">
          <SocialMediaLinks/>
        </div>
      </div>
    </footer>
    <vue-snotify/>
    <AppConfiguration/>
    <CookiePolicy/>
    <Announcements/>
    <ReleaseUpdate/>
  </div>
</template>

<script>
import Navigation from './components/Navigation';
import RecommendDialog from './components/dialogs/RecommendDialog';
import CookiePolicy from './components/CookiePolicy';
import PrivacyStatementView from "./components/views/PrivacyStatementView";
import Announcements from "./components/messages/Announcements";
import ReleaseUpdate from "./components/messages/ReleaseUpdate";
import AppConfiguration from "./components/control/AppConfiguration";
import TestPhaseOverCard from "./components/cards/TestPhaseOverCard";
import SocialMediaLinks from "./components/views/social/SocialMediaLinks";
import BackupService from "./services/BackupService";
import HttpErrorHandler from "./services/HttpErrorHandler";
import Vue from "vue";

export default {
  name: 'app',
  components: {
    SocialMediaLinks,
    AppConfiguration,
    Announcements,
    ReleaseUpdate,
    PrivacyStatementView,
    Navigation,
    RecommendDialog,
    CookiePolicy,
    TestPhaseOverCard,
  },

  data() {
    return {
      animation: {
        enter: {
          opacity: [1, 0],
          translateX: [0, -300],
          scale: [1, 0.2]
        },
        leave: {
          opacity: 0,
          height: 0
        }
      },

      darkModeState: false,
    }
  },

  watch: {
    isAuthenticated() {
      if (this.isAuthenticated) {
        this.restoreUserSettings();
        this.toggleDarkMode();
      }
    },

    darkMode() {
      if (this.isAuthenticated) {
        this.darkModeState = this.darkMode;
        this.toggleDarkMode();
      }
    },
  },

  methods: {

    restoreUserSettings() {
      if (localStorage.getItem(this.user.md5 + '@darkMode')) {
        this.darkModeState = JSON.parse(localStorage.getItem(this.user.md5 + '@darkMode'));
        this.toggleDarkMode();
      }
    },

    toggleDarkMode() {
      this.$store.commit('darkMode', this.darkModeState);
      if (this.darkMode) {
        Vue.material.theming.theme = 'dark';
      } else {
        Vue.material.theming.theme = 'default';
      }
      localStorage.setItem(this.user.md5 + '@darkMode', this.darkMode);

      HttpErrorHandler.maintainDarkMode(this, 10);
    },

    currentPath() {
      return this.$router.currentRoute.fullPath;
    },

    isOnRegistrationView() {
      return this.$router.currentRoute.fullPath.toLowerCase().includes('registrierung') ||
          (this.$route.name && this.$route.name.toLowerCase().includes('registration'));
    },

    isOnAccountActivationView() {
      return this.$router.currentRoute.fullPath.toLowerCase().includes('konto') ||
          (this.$route.name && this.$route.name.toLowerCase().includes('account'));
    },

    fullScreen() {
      return (this.$router.currentRoute.fullPath.toLowerCase().includes('/drucken/listen') ||
          this.$router.currentRoute.fullPath.toLowerCase().includes('dokumentation') ||
          this.printMode);
    },

    getHeaderClass() {
      return this.organization.timejumpActive && this.isAuthenticated ? 'background-header-time-jump' : 'background-header';
    },

    getNavClass() {
      return this.organization.timejumpActive && this.isAuthenticated ? 'background-nav-time-jump' : 'background-nav';
    },

    getFooterClass() {
      return this.organization.timejumpActive && this.isAuthenticated ? 'background-footer-time-jump' : 'background-footer';
    },

    onDeactivateTimeJumpBackup() {
      this.deactivateTimeJump();
    },

    deactivateTimeJump() {
      this.sending = true;
      BackupService.toggleTimeJump({customerNo: this.organization.customerNo})
          .then((response) => {
            this.sending = false;
            this.showActivateTimeJumpDialog = false;
            this.$store.commit('successMsg', 'Der Zeitsprung für Träger <b>' + this.organization.name + '</b> wurde erfolgreich deaktiviert');
            this.$store.commit('organizationInfo', response.data);
            setTimeout(() => this.$router.go(0), 3000);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Deaktivieren des Zeitsprungs für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          })
    },
  },

  computed: {

    printMode() {
      return this.$store.getters.printMode;
    },

    darkMode() {
      return this.$store.getters.darkMode;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    isPseudoSage() {
      return this.$store.getters.pseudoSage;
    },

    isParent() {
      return this.$store.getters.parent;
    },

    user() {
      return this.$store.getters.user;
    },

    organization() {
      return this.$store.getters.organization;
    },

    error: {
      get: function () {
        return this.$store.getters.errorMsg !== '';
      },

      set: function (newValue) {
        if (newValue === false) {
          this.$store.commit('errorMsg', '');
        }
      }
    },

    errorMsg() {
      return this.$store.getters.errorMsg;
    },

    success: {
      get: function () {
        return this.$store.getters.successMsg !== '';
      },

      set: function (newValue) {
        if (newValue === false) {
          this.$store.commit('successMsg', '');
        }
      }
    },

    successMsg() {
      return this.$store.getters.successMsg;
    },

    notification: {
      get: function () {
        return this.$store.getters.notificationMsg !== '';
      },

      set: function (newValue) {
        if (newValue === false) {
          this.$store.commit('notificationMsg', '');
        }
      }
    },

    notificationMsg() {
      return this.$store.getters.notificationMsg;
    },

    warning: {
      get: function () {
        return this.$store.getters.warningMsg !== '';
      },

      set: function (newValue) {
        if (newValue === false) {
          this.$store.commit('warningMsg', '');
        }
      }
    },

    warningMsg() {
      return this.$store.getters.warningMsg;
    },

    announcement: {
      get: function () {
        return this.$store.getters.announcementMsg !== '';
      },

      set: function (newValue) {
        if (newValue === false) {
          this.$store.commit('announcementMsg', '');
        }
      }
    },

    announcementMsg() {
      return this.$store.getters.announcementMsg;
    },

    version: {
      get: function () {
        return this.$store.getters.versionMsg !== '';
      },

      set: function (newValue) {
        if (newValue === false) {
          this.$store.commit('versionMsg', '');
        }
      }
    },

    versionMsg() {
      return this.$store.getters.versionMsg;
    },
  }
}
</script>

<style lang="scss" scoped>
.md-tooltip {
  font-size: medium;
  white-space: normal;
}

.md-tooltip b {
  color: orange;
}
</style>

<style lang="scss">

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/materialicons-v41.woff2') format('woff2');
}
@import './assets/css/animate.min.css';
@import './assets/scss/app';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.header-hide {
  transition: height 0.5ms ease-in-out;
  height: 0;
}

.header-show {
  transition: height 0.5ms ease-in-out;
  height: 65px;
}

#header h4, #header h6 {
  margin: 0;
  color: mintcream;
}

#header a:hover {
  text-decoration: none;
}

.my-account {
  transition: font-size 0.25s ease-in-out;
  font-size: 1rem;
  color: mintcream;
}

.my-account:hover {
  transition: font-size 0.25s ease-in-out;
  font-size: 1.2rem;
  color: #e1faea;
}

.sticky {
  z-index: 5;
}

.content {
  min-height: calc(100vh - 80px);
}

#footer {
  width: 100%;
  z-index: 10;
  height: 80px;
}

.background-footer {
  background: gray; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* Standard syntax */
}

.background-header {
  background: gray; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* Standard syntax */
}

.background-nav {
  background: gray; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* Standard syntax */
}

.background-footer-time-jump {
  background: gray; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(168, 156, 81, 0.7), rgba(211, 196, 102, 0.7)); /* Standard syntax */
}

.background-header-time-jump {
  background: gray; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(128, 128, 128, 0.7), rgba(154, 154, 154, 0.7)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(119, 110, 57, 0.7), rgba(168, 156, 81, 0.7)); /* Standard syntax */
}

.background-nav-time-jump {
  background: gray; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(rgba(154, 154, 154, 0.7), rgba(211, 211, 211, 0.7)); /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(168, 156, 81, 0.7), rgba(211, 196, 102, 0.7)); /* Standard syntax */
}

.test-phase {
  color: greenyellow;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0;
}

.test-phase b {
  font-size: 0.9rem;
  font-weight: 400;
}

.test-phase-expired-true {
  color: crimson;
}

.register {
  text-transform: none;
  font-weight: 300;
  font-size: 1.1rem;
}

h4.register {
  margin: 0;
}

@keyframes flashActive {
  0% {
    font-size: 14px !important;
    opacity: 1;
  }

  50% {
    font-size: 24px !important;
    opacity: 0;
  }

  100% {
    font-size: 14px !important;
    opacity: 1;
  }
}
</style>

