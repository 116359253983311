<template>
  <div v-if="isVisible && !isSage">

    <div v-if="!recommendationDisabled">
      <h4 id="recommendation-button-id" class="recommend" @click="onRecommend"><span class="rotated">☆</span>&nbsp;<b>SoSE</b> empfehlen</h4>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDialog" @md-closed="closeDialog">
      <md-dialog-title>
        <h4 class="recommend-title" @click="showDialog = true"><span class="rotated">☆</span>&nbsp;<b>SoSE</b> empfehlen
        </h4>
      </md-dialog-title>

      <md-dialog-content id="recommendDialogContentId">

        <form novalidate @submit.prevent="validateRequester">

          <div v-if="!requestConfirmed" class="grid-x grid-padding-x">
            <div class="cell small-12" style="text-align: justify">
              <h5>
                Wir freuen uns, dass Sie <b>SoSE</b> nutzen und mit unserem Service zufrieden sind.&nbsp;
                Um noch mehr soziale Einrichtungen bei der täglichen Arbeit unterstützen zu können, würden wir uns über
                Ihre Weiterempfehlung sehr freuen.
                <br><br>
                Bei <b>erfolgreicher Empfehlung</b> verringert sich Ihre Gebühr für ein Jahr um <b>5%</b>.
                <br><br>
              </h5>
            </div>
            <div class="cell large-12 medium-12 small-12">
              <md-field>
                <label>Ihr Name</label>
                <md-input name="recommender-name" id="recommender-name"
                          v-model="recommenderName" disabled/>
              </md-field>
            </div>
            <div class="cell large-6 medium-12 small-12">
              <md-field :class="getValidationClass('organizationName')">
                <label for="organization-name">Empfehlen für Einrichtung</label>
                <md-input name="organization-name" id="organization-name" autocomplete="off"
                          v-model="recommendationInfo.organizationName" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.recommendationInfo.organizationName.required">Einrichtungsname ist notwendig</span>
              </md-field>
            </div>
            <div class="cell large-6 medium-12 small-12">
              <md-field :class="getValidationClass('email')">
                <label for="recommendationInfo-email">Email der Einrichtung</label>
                <md-input type="email" name="recommendationInfo-email" id="recommendationInfo-email"
                          autocomplete="off"
                          v-model="recommendationInfo.email" :disabled="sending"/>
                <span class="md-error"
                      v-if="!$v.recommendationInfo.email.required">Email-Adresse ist notwendig</span>
                <span class="md-error"
                      v-else-if="!$v.recommendationInfo.email.email">Ungültige Email-Adresse</span>
              </md-field>
            </div>
            <div class="cell large-12 medium-12 small-12 text-justify">
              <br>
              <label><i>Ihre Nachricht</i></label>
              <br>
              <span style="padding-top: 5px" v-html="recommendationInfo.message"></span>
            </div>
            <div class="cell large-12 medium-12 small-12">
              <br>
              <md-checkbox class="md-primary" v-model="recommendationInfo.allowDataProcessAndForwardToThirdParty">
                Ich stimme zu, meinen Namen <b style="color: cornflowerblue">{{recommenderName}}</b> und meine E-Mail Adresse
                <b style="color: cornflowerblue">{{user.email}}</b> an die dritte Person im Rahmen dieser einmaligen Empfehlung zu übermitteln.
              </md-checkbox>
              <span :class="getValidationClass('allowDataProcessAndForwardToThirdParty')">
                <div class="md-error-checkbox" style="margin-top: 20px; margin-bottom: 20px;"
                     v-if="$v.recommendationInfo.allowDataProcessAndForwardToThirdParty.$invalid && $v.recommendationInfo.allowDataProcessAndForwardToThirdParty.$dirty">Diese Zustimmung ist notwendig<br><br></div>
                <div v-else>
                  <br><br><br><br>
                </div>
              </span>
            </div>


            <div class="cell small-6 text-center">
              <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="siteKey"
                  @verify="onVerify"
                  @expired="onExpired">
              </vue-recaptcha>
            </div>
          </div>

          <div v-else class="grid-x grid-padding-x">
            <div class="cell text-center">
              <h5>
                <br>Ihre <b>EMPFEHLUNG</b> wurde <span
                  v-if="recommendationExists">bereits </span><b>erfolgreich</b> übermittelt.<br>
                <span v-if="!recommendationExists">
                  <br>Die Einrichtung <b style="color: cornflowerblue">{{recommendationInfo.organizationName}}</b> erhält von uns in Kürze eine <b>Email mit Ihrer Empfehlung</b>.<br>
                  <br>Vielen Dank für Ihre Unterstützung!
                </span>
              </h5>
            </div>
          </div>

          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>

          <md-card-actions>
            <div class="cell text-center">
              <button v-if="!requestConfirmed" class="button success" type="submit"
                      :disabled="sending || recaptchaResponse.length === 0">
                <i>&#10003;</i>&nbsp;&nbsp;&nbsp; SoSE jetzt empfehlen
              </button>
              <button v-else class="button success" @click="resetRecommendationInfo" :disabled="sending">
                <i>&#10003;</i>&nbsp;&nbsp;&nbsp; Fenster schließen
              </button>
            </div>
          </md-card-actions>
        </form>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

  </div>

</template>

<script>
  import jQuery from 'jquery';
  import OrganizationService from '../../services/OrganizationService'
  import RecommendationInfo from '../../entities/RecommendationInfo'
  import HttpErrorHandler from '../../services/HttpErrorHandler';

  import VueRecaptcha from 'vue-recaptcha';

  import {validationMixin} from 'vuelidate'
  import {
    required,
    email,
    sameAs,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'RecommendDialog',
    mixins: [validationMixin],

    components: {
      VueRecaptcha,
    },

    props: {
      isVisible: {
        required: true
      },
    },

    mounted() {
      this.resetRecommendationInfo();
    },

    data: () => ({
      showDialog: false,
      success: true,
      sending: false,
      requestConfirmed: false,
      recommendationInfo: RecommendationInfo.recommendationInfo,
      isInfinity: false,
      duration: 5000,
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaResponse: '',
      recommenderName: '',
      recommendationExists: false,
    }),

    updated() {
      this.recommenderName = this.user.firstName + ' ' + this.user.lastName;
      this.buildMessage();
    },

    validations: {
      recommendationInfo: {
        email: {
          required,
          email,
        },
        organizationName: {
          required,
        },
        allowDataProcessAndForwardToThirdParty: {
          sameAs: sameAs(() => true),
        },
      },
    },

    methods: {

      onRecommend() {
        if (!this.isAuthenticated) {
          this.$router.push('/Login');
        } else {
          if (this.recommendationExists || this.requestConfirmed) {
            this.resetRecommendationInfo();
          }
          this.$v.$reset();
          this.showDialog = true;
        }
      },

      onVerify: function (response) {
        this.recaptchaResponse = response;
        localStorage.setItem('x-recaptcha', response);
      },

      onExpired: function () {
        this.resetCaptcha();
      },

      resetCaptcha() {
        this.recaptchaResponse = '';
        localStorage.removeItem('x-recaptcha');
      },

      buildMessage() {
        this.recommendationInfo.message = 'Sehr geehrte Damen und Herren der Einrichtung <b style="color: cornflowerblue">' + this.recommendationInfo.organizationName + '</b>,<br><br>' +
          'ich empfehle Ihnen die <b style="color: cadetblue">So</b>ftware für <b style="color: cadetblue">S</b>oziale <b style="color: cadetblue">E</b>inrichtungen (<b style="color: cadetblue">SoSE</b>).<br><br>' +
          '<b style="color: cadetblue">SoSE</b> ist das optimierte Werkzeug zur Verwaltung von Kindergärten, Krippen, Horte oder Häuser für Kinder.<br>' +
          'Die intuitive Dateneingabe ermöglicht einfache Bedienung und schnelle Unterstützung bei der täglichen Arbeit.<br><br>' +
          'Viele Grüße,<br>' +
          '<b style="color: cornflowerblue">' + this.user.firstName + ' ' + this.user.lastName + '</b>';
      },

      recommend() {
        this.sending = true;
        this.success = false;
        OrganizationService.recommend(this.recommendationInfo)
          .then(response => {
            this.form = response.data;
            this.success = true;
            this.sending = false;
            this.requestConfirmed = true;
          })
          .catch(e => {
            this.sending = false;
            if (e.response.data.error === 'ALREADY_ADVERTIZED') {
              this.recommendationExists = true;
              this.requestConfirmed = true;
            } else {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Senden der Empfehlung an ' + this.recommendationInfo.email);
            }
          })
      },

      getValidationClass(fieldName) {
        let field = this.$v.recommendationInfo[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateRequester() {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.recommend();
        }

        jQuery('#recommendDialogContentId').animate({scrollTop: 230}, 'slow');
      },

      resetRecommendationInfo() {
        this.$v.$reset();
        this.requestConfirmed = false;
        this.recommendationExists = false;
        this.showDialog = false;
        this.recommendationInfo.email = '';
        this.recommendationInfo.organizationName = '';
        this.recommendationInfo.message = '';
        this.recommendationInfo.allowDataProcessAndForwardToThirdParty = false;
      },

      closeDialog() {
        this.showDialog = false;
      },
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      recommendationDisabled() {
        return this.$store.getters.webAppConfig.recommendationDisabled;
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },
    },
  };
</script>

<style lang="scss" scoped>

  .md-dialog /deep/.md-dialog-container {
    width: 1000px;
    max-height: 95%;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
  }

  h5 b {
    font-weight: 500;
    color: cadetblue;
  }

  .md-content {
    padding: 10px 16px 4px 16px;
    width: 100%
  }

  .md-checkbox {
    margin: 8px 8px 8px 0;
  }

  .md-error-checkbox {
    color: red;
    font-size: 12px;
    margin-top: 40px;
    margin-left: 36px;
  }

  .md-tooltip {
    font-size: medium;
  }

  @import "~vue-material/dist/theme/engine";

  @include md-register-theme("selection-black", (
      accent: md-get-palette-color(black, 500)
  ));

  @include md-register-theme("selection-orange", (
      accent: md-get-palette-color(orange, 500),
      theme: dark
  ));

  @import "~vue-material/dist/base/theme";
  @import "~vue-material/dist/components/MdContent/theme";

  .recommend {
    cursor: pointer;
  }

  h4.recommend {
    transition: font-size 0.25s ease-in-out, color 0.25s ease-in-out;
    color: inherit;
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 6px;
  }

  h4.recommend b {
    font-weight: 400;
    color: crimson;
  }

  h4.recommend:hover {
    transition: font-size 0.25s ease-in-out, color 0.25s ease-in-out;
    color: seagreen;
    font-size: 1.4rem;
  }

  .md-theme-dark h4.recommend:hover {
    color: springgreen;
  }

  h4.recommend b {
    font-weight: 400;
    color: crimson;
  }

  h4.recommend-title {
    color: inherit;
    font-weight: 300;
    font-size: 1.4rem;
  }

  h4.recommend-title b {
    font-weight: 400;
    color: crimson;
  }

  .rotated {
    width: 1.5rem;
    font-size: 1.5rem;
    transition: transform 0.5s ease-in-out;
    transform: rotate(0deg);
  }

  .rotated:hover {
    transition: transform 0.5s ease-in-out;
    transform: rotate(180deg);
  }

</style>
