<template>
  <div>
  </div>
</template>

<script>
  export default {
    name: 'ReleaseUpdate',

    created() {
      setTimeout(() => this.showAppVersionMsg(), 3000);
      setTimeout(() => this.showReleaseUpdateMsg(), 5000);

      this.announcementRefreshIntervalId = setInterval(() => {
        if (this.windowActive) {
          this.showReleaseUpdateMsg();
          this.showAppVersionMsg();
        }
      }, 1000 * Number(process.env.VUE_APP_SOSE_CONFIGURATION_REFRESH_INTERVAL_SECONDS));
    },

    mounted() {
      window.addEventListener('focus', () => this.windowActive = true, { passive: true });
      window.addEventListener('blur', () => this.windowActive = false, { passive: true });
    },

    beforeDestroy() {
      clearInterval(this.announcementRefreshIntervalId);
    },

    data() {
      return {
        announcementRefreshIntervalId: 0,
        windowActive: true,
      }
    },

    methods: {

      showReleaseUpdateMsg() {
        console.log('version ' + JSON.stringify(this.version));
        console.log('webAppConfig ' + this.webAppConfig.soseWebAppVersion);
        if (this.version && this.webAppConfig) {
          if (this.webAppConfig.soseWebAppVersion !== this.version.frontend.version) {
            console.log('New WebApp version available.');
            console.log('Current version : ' + this.version.frontend.version);
            console.log('    New version : ' + this.webAppConfig.soseWebAppVersion);

            const webAppReloadDoneForVersion = localStorage.getItem('WebAppReloadDoneForVersion');
            console.log('webAppReloadDoneForVersion : ' + webAppReloadDoneForVersion);

            if (!webAppReloadDoneForVersion || (webAppReloadDoneForVersion && webAppReloadDoneForVersion !== this.webAppConfig.soseWebAppVersion)) {
              this.$store.commit('notificationMsg',
                  'Lieber Kunde,<br> wir haben die neue SoSE-Version <b>' + this.webAppConfig.soseWebAppVersion + '</b> bereitgestellt.<br><br>' +
                  'Die neue Version wird nun geladen.<br><br>' +
                  '<span style="font-size: larger;">Einen Moment bitte...</span><br>');

              setTimeout(() => {
                console.log(' Triggering reload for new version : ' + this.webAppConfig.soseWebAppVersion);
                localStorage.setItem('WebAppReloadDoneForVersion', this.webAppConfig.soseWebAppVersion);
                location.reload();
              }, 10000)
            }
          } else {
            localStorage.setItem('WebAppReloadDoneForVersion', this.webAppConfig.soseWebAppVersion);
          }
        }
      },

      showAppVersionMsg() {
        if (this.isAuthenticated) {
          const webAppReloadDoneForVersion = localStorage.getItem('WebAppReloadDoneForVersion');
          const reloadForNewVersionDone = (webAppReloadDoneForVersion && webAppReloadDoneForVersion === this.webAppConfig.soseWebAppVersion);

          if (!localStorage.getItem(this.user.md5 + '@Version' + this.version.frontend.version) &&
              !sessionStorage.getItem('sose.versionMsg.alreadyShown') &&
              reloadForNewVersionDone) {
            sessionStorage.setItem('sose.versionMsg.alreadyShown', this.version.frontend.version);
            this.$store.commit('versionMsg',
                '<br>Lieber Kunde,<br> wir haben die neue SoSE-Version <b>' + this.version.frontend.version + '</b> bereitgestellt.<br><br>' +
                'Die Neuerungen findest du unter ' +
                '<a href="https://www.ai42.de/release-notes/sose-release-notes" target="_blank" style="color: greenyellow">Release Informationen</a>.<br><br>' +
                '<a href="https://twitter.com/SoSETweety" target="_blank" style="color: white">' +
                'Folge uns auch auf Twitter <span><i class="fi-social-twitter" style="font-size: xx-large; color: #1ea1f1"></i></span></a><br><br><br>' +
                '<span style="font-size: larger;">Du bist zufrieden?</span><br>' +
                'Empfehle uns weiter, es lohnt sich!<br><br>');
          }
        }
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      user() {
        return this.$store.getters.user;
      },

      webAppConfig() {
        return this.$store.getters.webAppConfig;
      },

      version() {
        return this.$store.getters.version;
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>

