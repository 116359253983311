import Vue from 'vue';

export default {

  name: 'HttpUrlService',

  mainVue: null,

  init(mainVue) {
    this.mainVue = mainVue;
    let serverUrls = process.env.VUE_APP_SOSE_SERVER_LIST;
    serverUrls = serverUrls.split(',');
    let serverList = [];
    serverUrls.forEach(url => {
      serverList.push({url: url, available: true, failureTime: null, nextConnectTime: null, lastAccessTime: null,});
    });
    this.mainVue.$store.commit('serverList', serverList);

    if (serverList.length > 0) {
      this.mainVue.$store.commit('serverListIndex', 0);
    }
  },

  getCurrentBaseUrl() {
    let serverList = this.mainVue.$store.getters.serverList;
    return serverList[this.mainVue.$store.getters.serverListIndex].url;
  },

  setUrlUnavailable(url) {
    let unavailableUrl = null;
    let serverList = this.mainVue.$store.getters.serverList;

    serverList.forEach(server => {
      if (url.includes(server.url)) {
        server.available = false;
        server.failureTime = Vue.moment();
        server.nextConnectTime = Vue.moment().add(process.env.VUE_APP_SOSE_SERVER_RECONNECT_WAIT_TIME_SECONDS, 'seconds');
        this.mainVue.$store.commit('serverList', serverList);
        unavailableUrl = server.url;
      }
    });

    return unavailableUrl;
  },

  setUrlAvailable(url) {
    let availableUrl = null;
    let serverList = this.mainVue.$store.getters.serverList;

    serverList.forEach(server => {
      if (url.includes(server.url)) {
        server.available = true;
        server.failureTime = null;
        server.nextConnectTime = null;
        this.mainVue.$store.commit('serverList', serverList);
        availableUrl = server.url;
      }
    });

    return availableUrl;
  },

  getUrl(key) {
    let index = this.mainVue.$store.getters.serverListIndex;
    if (index >= 0) {
      let serverList = this.mainVue.$store.getters.serverList;

      let newServerBaseUrl = null;
      let newServerFound = false;
      let serverCount = 0;

      while (newServerFound === false && serverCount < serverList.length) {

        this.mainVue.$store.commit('serverListIndex', (index + 1) % serverList.length);
        index = this.mainVue.$store.getters.serverListIndex;

        if (serverList[index].available) {
          newServerBaseUrl = serverList[index].url;
          newServerFound = true;
        } else if (serverList[index].nextConnectTime && Vue.moment().isAfter(serverList[index].nextConnectTime)) {
          sessionStorage.setItem('sose.reconnect', 'true');
          this.setUrlAvailable(serverList[index].url);
          newServerBaseUrl = serverList[index].url;
          newServerFound = true;
        }

        if (newServerFound) {
          serverList[index].lastAccessTime = Vue.moment();
          this.mainVue.$store.commit('serverList', serverList);
        }

        serverCount++;
      }

      if (newServerBaseUrl === null) {
        this.setUrlAvailable(serverList[index].url);
        return null;
      }

      if (key === 'VUE_APP_SOSE_SERVER_OAUTH_URL') {
        return newServerBaseUrl + process.env.VUE_APP_SOSE_SERVER_OAUTH_CONTEXT;
      }
      if (key === 'VUE_APP_SOSE_SERVER_API_URL') {
        return newServerBaseUrl + process.env.VUE_APP_SOSE_SERVER_API_CONTEXT;
      }
    }
  },

  isValidRoute(challengeRoute) {
    let routeValid = false;

    this.mainVue.$router.options.routes.forEach(route => {
      if (route.path.lastIndexOf(':') > 0) {
        let path = route.path.substring(0, route.path.lastIndexOf(':') - 1);
        if (path.length > 1 && path === challengeRoute.substring(0, path.length)) {
          routeValid = true;
        }
      } else {
        if (route.path.length > 1 && route.path === challengeRoute.substring(0, route.path.length)) {
          routeValid = true;
        }
      }
    })

    return routeValid;
  },

  isAccessibleRoute(challengeRoute) {
    let routeAccessible = false;
    if (challengeRoute.endsWith(':id')) {
      challengeRoute = challengeRoute.substring(0, challengeRoute.length - 3)
    }
    this.mainVue.$store.getters.navigationRoutes.forEach(route => {

      if (route.path.length > 1 && challengeRoute === route.path.substring(0, challengeRoute.length)) {
        if (this.isRouteAllowedForUser(route)) {
          routeAccessible = true;
        }
      }

      if (route.subMenu.length > 0) {
        route.subMenu.forEach(subRoute => {
          if (subRoute.path.length > 1 && challengeRoute === subRoute.path.substring(0, challengeRoute.length)) {
            if (this.isRouteAllowedForUser(subRoute)) {
              routeAccessible = true;
            }
          }
        })
      }
    })

    return routeAccessible;
  },

  isRouteAllowedForUser(route) {
    if (!sessionStorage.getItem('sose.access_token')) {
      return false;
    }

    if (route.beta) {
      if (!this.mainVue.$store.getters.beta) {
        return false;
      }
    }

    const user = this.mainVue.$store.getters.user;

    for (let userRole of user.roles) {
      for (let routeRole of route.roles) {
        if (routeRole.startsWith('!')) {
          if (userRole === routeRole.substring(1)) {
            return false;
          }
        } else {
          if (userRole === routeRole) {
            return true;
          }
        }
      }
    }

    return false;
  },
}
