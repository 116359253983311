<template>
  <div class="off-canvas-wrapper">
    <div class="off-canvas position-bottom cookie-policy" id="offCanvasAcceptCookies" data-off-canvas data-transition="push">
      <div class="grid-x grid-padding-x">
        <div class="cell large-9" style="text-align: left;">
          <h4 v-if="!isAuthenticated">Lieber Besucher,</h4>
          <h4 v-else>Liebe(r) <b>SoSE</b> Benutzer(in) <b>{{user.username}}</b>,</h4>
          <h5 style="text-align: justify;">Wir verwenden derzeit <b>keine Cookies</b>.
            Stattdessen werden anwendungsspezifische Informationen in den sog. “Local Storage” und “Session Storage” abgelegt.
            Funktionen für soziale Medien, Analyse der Website und Werbung kommen nicht zum Einsatz.
            Erfahren Sie mehr hierzu in unserer <b><a href="https://ai42.de/unternehmen/datenschutz" target="_blank">Datenschutzerklärung</a></b>.
          </h5>
        </div>
        <div class="cell large-3">
          <div class="grid-x grid-padding-x">
            <div class="cell large-1 hide-for-medium-only hide-for-small-only">&nbsp;</div>
            <div class="cell large-10 medium-5 text-center">
              <button type="button" class="button secondary expanded" @click="onAcceptCookies()"
                      data-toggle="offCanvasAcceptCookies">
                <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Hinweis gelesen
              </button>
            </div>
            <div class="cell large-1 medium-7 hide-for-small-only">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
    <div class="off-canvas-content" style="height: 0px; width: 0px;" data-off-canvas-content>
    </div>
  </div>
</template>

<script>
  import jQuery from 'jquery';

  export default {
    name: 'CookiePolicy',

    components: {
    },

    created() {
      this.cookiesAccepted = localStorage.getItem('SoSEWebAppAcceptCookies');
    },

    mounted() {
      this.toggleCookieCanvas();
    },

    data() {
      return {
        cookiesAccepted: false,
        cookiesAcceptedByUser: false,
        canvasOpen: false,
      }
    },

    watch: {
      user() {
        this.toggleCookieCanvas();
      },

      isParent() {
        this.toggleMenuStyle(this.isParent);
      },
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      user() {
        return this.$store.getters.user;
      },

      isParent() {
        return this.$store.getters.parent;
      },
    },

    methods: {

      toggleCookieCanvas() {
        if (this.user && this.user.email) {
          this.cookiesAcceptedByUser = localStorage.getItem(this.user.md5 + '@SoSEWebAppAcceptCookies');
        }
        this.cookiesAccepted = localStorage.getItem('SoSEWebAppAcceptCookies');
        // jQuery('.js-off-canvas-overlay.is-overlay-fixed.is-visible.is-closable').remove();
        // jQuery('#offCanvasAcceptCookies').remove();
        if ((!this.cookiesAccepted && !this.isAuthenticated) || (!this.cookiesAcceptedByUser && this.isAuthenticated)) {
          setTimeout(() => {
            jQuery('#offCanvasAcceptCookies').foundation('open');
            // jQuery('.js-off-canvas-overlay.is-overlay-fixed.is-visible.is-closable').remove();
            this.canvasOpen = true;
          }, 500);
        } else {
          // jQuery('.js-off-canvas-offCanvasAcceptCookiesoverlay.is-overlay-fixed.is-visible.is-closable').remove();
          setTimeout(() => {
            if (this.canvasOpen) {
              jQuery('#offCanvasAcceptCookies').foundation('close');
              this.canvasOpen = false;
            }
          }, 500);
        }
      },

      toggleMenuStyle(toggle) {
        if (toggle) {
          jQuery('.off-canvas.position-bottom').addClass('parents-cookie-policy');
        } else {
          jQuery('.off-canvas.position-bottom').removeClass('parents-cookie-policy');
        }
      },

      onAcceptCookies() {
        localStorage.setItem('SoSEWebAppAcceptCookies', 'true');
        this.cookiesAccepted = true;
        if (this.user && this.user.email) {
          localStorage.setItem(this.user.md5 + '@SoSEWebAppAcceptCookies', 'true');
          this.cookiesAcceptedByUser = true;
        }
        this.toggleCookieCanvas();
      },
    }
  }
</script>

<style lang="scss" scoped>
  .parents-cookie-policy {
    background: #cae0ea !important;
  }

  .cookie-policy {
    padding: 16px 5px 10px 5px;
    height: auto;
    background: #e1faea;
    z-index: 99999;
  }

  .cookie-policy h5 {
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 300;
    font-size: 1.1rem;
  }

  .cookie-policy h4 {
    padding-left: 0px;
    padding-right: 0px;
    font-weight: 300;
    font-size: 1.3rem;
  }

  .cookie-policy b {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .cookie-policy button {
    margin-top: 20px;
  }
</style>
