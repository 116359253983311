import axios from 'axios';

export default {

  name: 'ContentService',

  httpClient(path) {
    const options = {
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded', 'accept': 'application/json' },
      url: process.env.VUE_APP_SOSE_WEB_CMS_CONTENT_API_URL + path,
    };

    return axios(options);
  },

  getContent(id) {
    return this.httpClient('pages/' + id);
  },
}
