import Person from "../entities/Person"
import Address from "../entities/Address"
import Contact from "../entities/Contact"
import BankAccount from "./BankAccount";

export default {

  name: 'Organization',

  organization: {
    customerNo: '',
    name: '',
    organizationType: null,
    associationRegistrationNumber: '',
    email: '',
    testPhaseActive: false,
  },

  fullOrganization: {
    address: Address.address,
    allowedModules: [],
    associationRegistrationNumber: '',
    bankAccount: BankAccount.bankAccount,
    baseModuleActive: false,
    contact: Contact.contact,
    creditorId: '',
    customerNo: '',
    customerState: '',
    dataSecurityModuleActive: false,
    documentationStandaloneModuleActive: false,
    email: '',
    emailHash: '',
    employeeModuleActive: false,
    facilities: [],
    financeModuleActive: false,
    id: '',
    messagingModuleActive: false,
    name: '',
    organizationType: '',
    organizationTypeOptions: [],
    partner: false,
    payingCustomer: false,
    responsiblePerson: Person.person,
    settlementEmail: '',
    testPhaseActive: false,
    testPhaseExpired: false,
    testPhaseUntil: null,
  },

  ai42Organization: {
    name: 'ai42',
    responsiblePerson: {salutation: 'Sage', firstname: 'AI', lastname: '42'},
    facilities: [],
    testPhaseActive: false,
    baseModuleActive: false,
    documentationStandaloneModuleActive: false,
    employeeModuleActive: false,
    financeModuleActive: false,
    messagingModuleActive: false,
  },
}
