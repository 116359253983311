import HttpInterceptor from './HttpInterceptor'

export default {

  name: 'BackupService',

  getSettings() {
    return HttpInterceptor.httpClient().get('backup/settings')
  },

  getAvailableBackups(customerNo) {
    return HttpInterceptor.httpClient().get('backup/' + customerNo)
  },

  createBackup(customerNo) {
    return HttpInterceptor.httpClient().post('backup/' + customerNo + '/create')
  },

  createBackupAndDownload(customerNo) {
    return HttpInterceptor.httpClient().post('backup/' + customerNo + '/create/download', {},{
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/zip'
      }
    })
  },

  toggleTimeJump(backupItem) {
    return HttpInterceptor.httpClient().post('backup/' + backupItem.customerNo + '/timejump/toggle', backupItem)
  },

  deleteBackup(customerNo, backupItem) {
    return HttpInterceptor.httpClient().post('backup/' + customerNo + '/delete', backupItem)
  },

  downloadBackup(customerNo, backupItem) {
    return HttpInterceptor.httpClient().post('backup/' + customerNo + '/download', backupItem, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/zip'
      }
    })
  },

  updateSettings(settings) {
    return HttpInterceptor.httpClient().put('backup/settings/update', settings)
  },
}
