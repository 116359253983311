import Vue from 'vue';
import './plugins/axios';
import FoundationCss from 'foundation-sites/dist/css/foundation.min.css';
import FoundationJs from 'foundation-sites';
import MotionUI from 'motion-ui';
import jQuery from 'jquery';
import App from './App.vue';
import router from './router';
import store from './store';
import InfoService from './services/InfoService';
import UserService from './services/UserService';
import HttpUrlService from './services/HttpUrlService';
import HttpErrorHandler from './services/HttpErrorHandler';
import OrganizationService from './services/OrganizationService';
import 'vue-material/dist/vue-material.min.css';
import VueMaterial from 'vue-material';
import VueMoment from 'vue-moment';
import HttpInterceptor from './services/HttpInterceptor';
import Snotify, {SnotifyPosition} from 'vue-snotify'
import SnackBar from './components/animations/SnackBar';
import Address from "./entities/Address";
import TestAccountData from "./entities/TestAccountData";
import * as Ably from 'ably';

Vue.component('snackbar', SnackBar);

const moment = require('moment');
Vue.use(VueMoment, {
  moment
});

Vue.moment.locale('de');

Vue.use(VueMaterial);
Vue.material.theming.theme = 'default';

Vue.use(MotionUI);

Vue.use(FoundationCss);
Vue.use(FoundationJs);

Vue.use(Snotify, {
    toast: {
      position: SnotifyPosition.centerTop,
      style: 'dark',
    }
  }
)

Vue.config.productionTip = false;

window.$ = require('jquery');
window.jQuery = require('jquery');
window.MotionUI = require('motion-ui');

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus();
  }
});

Vue.filter('currency', function (value) {
  return parseFloat(value).toFixed(2).replace('.', ',') + ' €';
});

Vue.filter("number", function (value) {
  return Number((value).toFixed(2)).toLocaleString();
});

new Vue({
  FoundationCss,
  FoundationJs,
  router,
  store,

  created() {
    document.title = process.env.VUE_APP_TITLE;

    HttpUrlService.init(this);
    HttpInterceptor.initInterceptors(this);
    HttpInterceptor.mainVue = this;

    // Initialize route history tracking
    this.$router.beforeEach((to, from, next) => {
      this.$store.commit('routingHistory', from.path);
      if (this.isAuthenticated && this.user && this.user.md5) {
        localStorage.setItem(this.user.md5 + '@lastRouterPath', to.path);
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      next();
    })

    // Restore session data from local storage after page reload
    this.$store.commit('session', null);

    if (this.isLoggedIn) {
      this.getLabels();
    } else {
      this.challengeLogin();
    }
  },

  destroyed() {
    this.unsubscribe();
  },

  mounted() {
    jQuery(document).foundation();

    this.$material.locale = {
      startYear: 1900,
      endYear: 2099,
      dateFormat: 'dd.MM.yyyy',
      days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      shortDays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      // For material datepicker the first day in shorterDays array must be 'S' Sunday for the others above it must be Monday (see BookingTimes, ChildAbsence, AssignmentPlan, etc.)
      shorterDays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
      months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'],
      shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Ju', 'Ju', 'A', 'Se', 'O', 'N', 'D'],
      firstDayOfAWeek: 1
    };
  },

  data() {
    return {
      subscriptions: [],
      realtime: null,

      demand: {
        account: {
          accountOwner: '',
          bankname: '',
          bic: '',
          dateOfMandateGranting: Vue.moment(),
          iban: '',
          mandateReference: '',
          numberOfSuccessfulSEPATransactions: 0,
          valid: true,
          mandateType: 'ONE_OFF'
        },

        advertisingToken: null,
        agbConfirmed: false,
        ai42Contract: null,
        contractConfirmed: true,
        dsgvoConfirmed: false,
        email: '',
        keepDataOfTestphase: false,
        modules: [],
        numChildren: 5,
        organizationAddress: Address.address,
        organizationName: '',
        organizationType: '',
        partnerMember: true,
        password: '',
        paymentInterval: 'MONTHLY',
        testAccountDemand: false,
        testAccountData: TestAccountData.testAccountData,
        useSepaForPayment: true,
      },
    }
  },

  methods: {

    getLabels() {
      InfoService.getLabels()
        .then(response => {
          this.$store.commit('labelsInfo', response.data);
          this.getUserInfo();
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Labels');
        });
    },

    getUserInfo() {
      UserService.getUserInfo()
        .then(response => {
          this.$store.commit('userInfo', response.data);
          this.getVersion();
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Benutzerdaten');
          this.logout();
        });
    },

    getVersion() {
      InfoService.getVersion()
        .then(response => {
          this.$store.commit('versionInfo', response.data);

          if (!this.isSage && !this.isParent) {
            this.getOrganizationInfo();
            this.getOrganizationContract();
          }

          if (this.isParent && !this.isSage) {
            this.extendRoutingBlackList();
            this.challengeLogin();
            // this.getPreFilledContract();
          }
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Versionsdaten');
        });
    },

    getOrganizationInfo() {
      OrganizationService.getOrganizationInfo()
        .then(response => {
          this.$store.commit('organizationInfo', response.data);
          this.$store.commit('availableFacilities');

          this.extendRoutingBlackList();
          this.challengeLogin();

          if (!this.isSage) {
            this.getPreFilledContract();
          }
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Trägerdaten');
        });
    },

    getOrganizationContract() {
      this.sending = true;
      OrganizationService.getOrganizationContract()
        .then(response => {
          this.$store.commit('organizationContract', response.data);
          this.sending = false;
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Vertragsdaten für Träger <b>' + this.organization.name + '</b>');
          this.sending = false;
        });
    },

    getPreFilledContract() {
      this.demand.modules = this.labels.moduleTypes.reverse();
      InfoService.getPreFilledContract(this.demand, true)
        .then(response => {
          this.$store.commit('modules', response.data.ai42Contract.modules);
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Modul-Informationen');
        });
    },

    logout() {
      this.$store.commit('logout');
    },

    extendRoutingBlackList() {
      if (this.isLoggedIn) {
        let extendedRoutingBlackList = this.routingBlackList;
        this.$router.options.routes.forEach(route => {

          if (HttpUrlService.isValidRoute(route.path)) {
            if (!HttpUrlService.isAccessibleRoute(route.path)) {
              let blackRoute = route.path;
              if (blackRoute.startsWith('/')) {
                blackRoute = blackRoute.substring(1);
              }
              extendedRoutingBlackList.push(blackRoute);
            }
          }
        });

        this.$store.commit('routingBlackList', this.routingBlackList);
      }
    },

    challengeLogin() {
      let currentRoute = window.location.pathname;
      let routingAllowed = currentRoute.length > 1;

      this.routingBlackList.forEach((route) => {
        if (currentRoute.toLowerCase().includes(route.toLowerCase()) && routingAllowed) {
          routingAllowed = false;
        }
      });

      if (routingAllowed && !this.isLoggedIn) {
        this.$store.commit('challengeLoginRoute', currentRoute);
        this.$router.push('/Login');
      }

      if (!routingAllowed && this.isLoggedIn) {
        this.$router.push('/');
      }

      if (!HttpUrlService.isValidRoute(currentRoute)) {
        this.$router.push('/');
      }
    },

    subscribe() {

      if (this.user && this.isAuthenticated && this.facilities && this.facilities.length > 0) {
        this.subscriptions = [];

        let options = {
          authUrl: this.$store.getters.serverUrl + 'msg/create/token/v2/' + this.organization.customerNo,
          authHeaders: {'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')},
        }

        this.realtime = new Ably.Realtime(options);

        if (this.realtime) {

          this.realtime.connection.once('connected', () => {

            console.log("Ably messaging successfully connected for organisation: " + this.organization.customerNo);

            for (let facility of this.facilities) {

              let channel = this.realtime.channels.get('facilities:' + facility.referenceNumber);
              channel.subscribe((message) => {
                this.onNextMessage(JSON.parse(message.data))
              });

              channel.on('attached', () => {

                console.log("Ably channel " + channel.name + " is now attached");

                this.subscriptions.push({
                  channel: channel,
                  subscribed: true,
                  referenceNumber: facility.referenceNumber,
                });

                channel.history((err, resultPage) => {
                  if (err) {
                    console.log('Ably history ErrorInfo: ' + JSON.stringify(err))
                  } else {
                    if (resultPage && resultPage.items) {
                      resultPage.items.forEach(message => {

                        if (message.name !== 'TEST') {
                          let lastMessageReceivedTime = localStorage.getItem(this.user.md5 + '@SoSEMessagingLastMessageReceivedTime');
                          if (lastMessageReceivedTime) {
                            if (Vue.moment.unix(JSON.parse(message.data).datetime).isAfter(Vue.moment(lastMessageReceivedTime, 'LL  LTS'))) {
                              console.log('Ably old message found: ' + message.data)
                              this.onNextMessage(JSON.parse(message.data));
                            }
                          } else {
                            this.onNextMessage(JSON.parse(message.data));
                          }
                        } else {
                          this.onNextMessage(message);
                        }
                      })
                    }
                  }
                });
              });
            }
          });

          this.realtime.connection.on('failed', () => {
            console.log("Ably connection is in state failed.");
            this.unsubscribe();
          });

          this.realtime.connection.on('suspended', () => {
            console.log("Ably connection is in state suspended.");
            this.unsubscribe();
          });
        }
      }
    },

    unsubscribe() {
      for (let subscription of this.subscriptions) {
        if (subscription.subscribed) {
          console.log('Ably unsubscribing for facility: ' + subscription.referenceNumber + ' ...');
          subscription.channel.unsubscribe();

          subscription.subscribed = false;
          subscription.referenceNumber = '';
        }
      }

      if (this.subscriptions && this.realtime) {
        console.log('Ably closing realtime client. ');
        this.realtime.close();
      }

      this.subscriptions = [];
    },

    onNextMessage(msg) {
      if (this.isAuthenticated) {
        localStorage.setItem(this.user.md5 + '@SoSEMessagingLastMessageReceivedTime', Vue.moment().format('LL  LTS'));

        if (msg.name !== 'TEST') {
          let content = JSON.stringify(msg);
          if (msg.data.text) {
            content = msg.data.text;
          }
          let textMsg = content;
          this.$store.commit('notificationMsg', textMsg);
        } else {
          this.$store.commit('notificationMsg', msg.data);
        }
      }
    },
  },

  watch: {
    organization() {
      if (!this.isParent) {
        if (this.notSubscribed) {
          this.subscribe();
        }
      }
    },

    isAuthenticated() {
      if (!this.isAuthenticated) {
        this.unsubscribe();
      }
    },

    session() {
      if (this.isAuthenticated && this.realtime) {
        this.realtime.connection.on('closed', () => {
          console.log("Ably connection is in state closed.");
          if (this.notSubscribed) {
            this.subscribe();
          }
        });

        this.unsubscribe();
      }
    },
  },

  computed: {

    notSubscribed() {
      return this.subscriptions.length <= 0;
    },

    isParent() {
      return this.$store.getters.parent;
    },

    isSage() {
      return this.$store.getters.sage;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isLoggedIn() {
      return sessionStorage.getItem('sose.access_token');
    },

    user() {
      return this.$store.getters.user;
    },

    labels() {
      return this.$store.getters.labels;
    },

    organization() {
      return this.$store.getters.organization;
    },

    facilities() {
      return this.$store.getters.allowedFacilities;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    routingBlackList() {
      return this.$store.getters.routingBlackList;
    },

    navigationRoutes() {
      return this.$store.getters.navigationRoutes;
    },
  },

  render: h => h(App)

}).$mount('#app');
