import HttpInterceptor from './HttpInterceptor'

export default {

  name: 'OrganizationService',

  getAllOrganizations() {
    return HttpInterceptor.httpClient().get('sage/organization/all')
  },

  getAllFacilities() {
    return HttpInterceptor.httpClient().get('organization/facilities')
  },

  getOrganizationInfo() {
    return HttpInterceptor.httpClient().get('organization/info')
  },

  getOrganizationContract() {
    return HttpInterceptor.httpClient().get('organization/contract')
  },

  getStateOfficeStatisticPreview(date, facilityReferenceNumbers, useGroups, autoGroup) {
    return HttpInterceptor.httpClient().put('organization/statistic/' + date + '?usegroups=' + useGroups + '&autogroup=' + autoGroup, facilityReferenceNumbers)
  },

  getStateOfficeStatisticCSV(date, facilityReferenceNumbers, useGroups, autoGroup) {
    return HttpInterceptor.httpClient().put('organization/statistic/' + date + '/csv?usegroups=' + useGroups + '&autogroup=' + autoGroup, facilityReferenceNumbers)
  },

  addModulesToOrganizationContract(modules) {
    return HttpInterceptor.httpClient().put('organization/contract/modules/add', modules)
  },

  removeModulesFromOrganizationContract(modules) {
    return HttpInterceptor.httpClient().put('organization/contract/modules/delete', modules)
  },

  changeNumberOfChildren(number) {
    return HttpInterceptor.httpClient().put('organization/contract/children/modify', number)
  },

  preCalcNumberOfChildren(number) {
    return HttpInterceptor.httpClient().put('organization/contract/children/modify/precalc', number)
  },

  cancelContract() {
    return HttpInterceptor.httpClient().put('organization/contract/cancel')
  },

  undoCancelContract() {
    return HttpInterceptor.httpClient().put('organization/contract/cancellation/cancel')
  },

  cancelRegulatoryContract(cancellation) {
    return HttpInterceptor.httpRecaptchaClient().put('organization/contract/cancel/regulatory', cancellation)
  },

  createOrganization(organization) {
    return HttpInterceptor.httpClient().post('sage/organization/create', organization)
  },

  updateOrganization(organization) {
    return HttpInterceptor.httpClient().put('organization/update', organization)
  },

  updateOrganizationForId(organization) {
    return HttpInterceptor.httpClient().put('sage/organization/' + organization.id + '/update', organization)
  },

  deleteOrganizationEntity(id) {
    return HttpInterceptor.httpClient().delete('sage/organization/delete/' + id + '/entity')
  },

  deleteTestAccountInquiry(id) {
    return HttpInterceptor.httpClient().delete('sage/organization/testaccount/' + id)
  },

  deleteOrganizationCompletely(customerNo) {
    return HttpInterceptor.httpClient().delete('sage/organization/delete/' + customerNo + '/completely')
  },

  reactivateTestAccount(id) {
    return HttpInterceptor.httpClient().put('sage/organization/testaccount/' + id + '/reactivate')
  },

  deactivateTestAccount(id) {
    return HttpInterceptor.httpClient().put('sage/organization/testaccount/' + id + '/deactivate')
  },

  addFacility(facility) {
    return HttpInterceptor.httpClient().post('organization/facility', facility)
  },

  recommend(recommendationInfo) {
    return HttpInterceptor.httpClient().post('organization/advertise', recommendationInfo)
  },

  createTestAccount(inquiryEmail) {
    return HttpInterceptor.httpClient().post('sage/organization/testaccount/create', inquiryEmail)
  },

  getAllTestAccountInquiries() {
    return HttpInterceptor.httpClient().get('sage/organization/testaccount/inquiries/all')
  },
}
