<template>
  <div class="text-center">
    <md-card md-with-hover class="text-left">
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
            <md-icon class="md-size-2x">timer_off</md-icon>
          </div>
          <div class="md-layout-item">
            <div class="md-title">TEST-LIZENZ abgelaufen</div>
            <div class="md-subhead">Ihr <span>Testzeitraum</span>&nbsp;ist beendet.</div>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
          </div>
          <div class="md-layout-item">
            Wir hoffen, sie konnten den vollen Funktionsumfang von SoSE testen und kennenlernen.
            <br><br><span>Überzeugt?</span><br>
            Konfigurieren und bestellen Sie jetzt gleich Ihr individuelles SoSE-Bundle.
            <br><br>
            <router-link to="/Registrierung">
              <button class="button expanded warn">
                SoSE JETZT BESTELLEN
              </button>
            </router-link>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  export default {
    name: 'TestPhaseOverCard',
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
    font-weight: bold;
  }

  .md-title {
    color: coral;
    padding-bottom: 1rem;
  }
</style>
