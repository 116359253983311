export default {

  name: 'RecommendationInfo',

  recommendationInfo: {
    'email': '',
    'organizationName': '',
    'message': '',
    'allowDataProcessAndForwardToThirdParty': false,
  }
}
