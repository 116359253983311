<template>
  <div v-if="!isSage" class="grid-x grid-padding-x">
      <div class="cell small-3"></div>
      <div class="cell small-2">
        <a href="https://twitter.com/SoSETweety" target="_blank" style="text-decoration: none">
          <div class="rotated release-icon"><i class="fi-social-twitter"></i></div>
          <md-tooltip>Folge uns auf Twitter <i class="fi-social-twitter"></i> ...<br>Hier berichten wir über aktuelle Entwicklungen.</md-tooltip>
        </a>
      </div>
      <div class="cell small-2">
        <a href="https://www.ai42.de/release-notes/sose-release-notes" target="_blank" style="text-decoration: none">
          <div class="rotated release-icon"><i class="fi-compass"></i></div>
          <md-tooltip>Mehr Informationen zu einzelnen Releases</md-tooltip>
        </a>
      </div>
      <div class="cell small-2">
        <a v-if="isAuthenticated && !recommendationDisabled && !isParent" style="text-decoration: none" @click="onOpenRecommendation">
          <div class="rotated star release-icon">☆</div>
          <md-tooltip>Zufrieden? Empfehlen Sie uns weiter, es lohnt sich für Sie!</md-tooltip>
        </a>
      </div>
      <div class="cell small-3"></div>
    </div>
</template>

<script>
  export default {
    name: 'SocialMediaLinks',

    methods: {
      onOpenRecommendation() {
        document.getElementById('recommendation-button-id').click();
      }
    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isParent() {
        return this.$store.getters.parent
      },

      isSage() {
        return this.$store.getters.sage;
      },

      recommendationDisabled() {
        return this.$store.getters.webAppConfig.soseRecommendationDisabled;
      },
    }
  }

</script>

<style lang="scss" scoped>

  .rotated {
    width: 1.5rem;
    font-size: 1.5rem;
    color: gray;
    transition: all 0.75s ease-in-out;
    transform: rotate(0deg);
    text-decoration: none;
  }

  .md-theme-dark .rotated {
    color: floralwhite;
  }

  .rotated:hover {
    transition: all 0.75s ease-in-out;
    transform: rotate(360deg);
    cursor: pointer;
    text-decoration: none;
    color: crimson;
  }

  .release-icon {
    font-size: 32px !important;
    margin-left: 17px;
    margin-top: 4px;
    margin-bottom: -4px;
    cursor: pointer;
    text-decoration: none;
  }

  .star {
    margin-top: 9px;
    margin-bottom: -9px;
    font-size: 25px !important;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: pre-wrap;
  }
</style>