<template>
  <div>
  </div>
</template>

<script>
  import ContentService from "../../services/ContentService";

  export default {
    name: 'Announcements',

    created() {
      setTimeout(() => this.getAnnouncementMsg(), 5000);

      this.announcementRefreshIntervalId = setInterval(() => {
        if (this.windowActive) {
          this.getAnnouncementMsg();
        }
      }, 1000 * Number(process.env.VUE_APP_SOSE_ANNOUNCEMENT_REFRESH_INTERVAL_SECONDS));
    },

    mounted() {
      window.addEventListener('focus', () => this.windowActive = true, { passive: true });
      window.addEventListener('blur', () => this.windowActive = false, { passive: true });
    },

    beforeDestroy() {
      clearInterval(this.announcementRefreshIntervalId);
    },

    data() {
      return {
        announcementRefreshIntervalId: 0,
        windowActive: true,
      }
    },

    methods: {

      getAnnouncementMsg() {
        if (this.webAppConfig.soseAnnouncementDisabled) {
          return;
        }

        ContentService.getContent(process.env.VUE_APP_SOSE_ANNOUNCEMENT_WP_ID)
          .then(response => {
            this.$store.commit('announcementMsg', response.data.content.rendered);
          })
          .catch(() => {
            this.$store.commit('announcementMsg', '');
          });
      },
    },

    computed: {

      webAppConfig() {
        return this.$store.getters.webAppConfig;
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>

