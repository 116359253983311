import HttpInterceptor from './HttpInterceptor'

export default {

  name: 'UserService',

  getUserInfo() {
    return HttpInterceptor.httpClient().get('users/info')
  },

  getAllUsers() {
    return HttpInterceptor.httpClient().get('users/all')
  },

  getProfiles() {
    return HttpInterceptor.httpClient().get('users/profiles')
  },

  createUser(newUser) {
    return HttpInterceptor.httpClient().post('sage/users/create', newUser)
  },

  addUser(newUser) {
    return HttpInterceptor.httpClient().post('users/add', newUser)
  },

  forgotPassword(user) {
    return HttpInterceptor.httpRecaptchaClient().put('users/forgotpassword', user)
  },

  deleteUser(userId) {
    return HttpInterceptor.httpClient().delete('users/delete/' + userId)
  },

  unlockUser(user) {
    return HttpInterceptor.httpClient().put('users/unlock', user)
  },

  updateUser(user) {
    return HttpInterceptor.httpClient().put('users/update/' + user.id, user)
  },

  takeOverIdentity(customerNo) {
    return HttpInterceptor.httpClient().put('sage/users/identity/takeover', customerNo)
  },

  resetIdentity() {
    return HttpInterceptor.httpClient().put('sage/users/identity/takeover/reset')
  },
}
