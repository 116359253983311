export default {

  name: 'BankAccount',

  bankAccount: {
    accountOwner: '',
    bankname: '',
    bic: '',
    dateOfMandateGranting: '',
    iban: '',
    mandateReference: '',
    mandateType: null,
    valid: true,
  }
}