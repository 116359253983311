export default {

  name: 'Feedback',

  feedback: {
    id: '',
    htmlTooltip: '',
    type: 'BUG',
    status: 'OPEN',
    title: '',
    text: '',
    ticketNo: '',
    customerNo: '',
    createdBy: '',
    createdDate: '',
    priority: 'NONE',
    ageInHours: 0,
  }
}
